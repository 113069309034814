import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AtlasPagesService, Type } from 'atlas-pages-loader';
import { BehaviorSubject, Subject } from 'rxjs';
import { CookieService } from './cookie.service';
import * as convertKeys from 'convert-keys';
import { Site } from '../model/site.model';
import { ApiService } from './api.service';
import { Organization } from '../model/organization.model';
import { SiteGroup } from '../model/site-group.model';
import { toCamel } from 'convert-keys';

@Injectable()
export class SitesService {
  atlasPagesService: AtlasPagesService;
  orgHierarchy$ = new BehaviorSubject<Type[]>([]);
  childrenList$ = new BehaviorSubject<any[]>(null);
  currentParent$: BehaviorSubject<any> = new BehaviorSubject(null);

  site$ = new BehaviorSubject<any>(null);
  potentialParentsList$ = new BehaviorSubject<any[]>(null);
  isInit = true;
  readonly selectedSiteId$ = new BehaviorSubject<string>(null);
  readonly siteList$ = new BehaviorSubject<any>([]);
  readonly selectedSite$ = new BehaviorSubject<Site>(null);
  readonly sites$ = new BehaviorSubject<any>(null);
  private readonly _loadingSite$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly errorLoadingSite$ = new BehaviorSubject<any>(null);
  loadingHierarchiesSites$: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  readonly loadingHierarchy$ = new BehaviorSubject<any>(false);
  readonly loadingParentSiteGroup$ = new BehaviorSubject<any>(false);

  constructor(private apiService: ApiService, private httpClient: HttpClient, private cookieService: CookieService) {
    this.initPageLoader();
  }

  initPageLoader() {
    this.atlasPagesService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async refetchOrgs() {
    this.initPageLoader();
    await this.getOrgHierarchies();
  }

  getLoadingParents() {
    return this.loadingParentSiteGroup$.asObservable();
  }

  async getOrgHierarchies(getSingle = false, item?, loader = false) {
    if (!this.loadingHierarchy$.value || item) {
      this.loadingHierarchy$.next(true);
      const orgHierarchy: Type[] = !getSingle
        ? await this.atlasPagesService.getSpecificHierarchies(['organization'])
        : await this.atlasPagesService.getSpecificHierarchies(
          ['organization', 'spacegroup', 'site'],
          { id: item.id, dr_type: item.dr_type.toUpperCase() }
        );
      this.filterOutEquipmentGroupsFromHierarchy(orgHierarchy)
      this.loadingHierarchy$.next(loader); // Please do not change this order :)
      this.orgHierarchy$.next(orgHierarchy);
    }
  }

  filterOutEquipmentGroupsFromHierarchy(orgHierarchy) {
    orgHierarchy.forEach((child, key) => {
      let childrenList
      if (child.children) {
        childrenList = child.children
        if (child.type == "Site") {
          delete child.children
          childrenList = child
          return
        }
        this.filterOutEquipmentGroupsFromHierarchy(childrenList)
      }
      if (child.core_space_type == "EQUIPMENT") {
        orgHierarchy.splice(key, 1)
        childrenList = orgHierarchy
        return
      }
      return
    })
  }

  async getPotentialParents() {
    if(!this.potentialParentsList$.getValue()) {
      this.loadingParentSiteGroup$.next(true);
      const allSpaces = await this.atlasPagesService.getSpecificHierarchies(['organization', 'spacegroup']);
      let allParents = [];

      const flattened = [];
      // temporary current node
      let node: any | undefined;
      // iterate until all nodes have been looked at
      while (allSpaces.length > 0) {
        node = allSpaces.pop();
        if (node === undefined) continue;
        flattened.push(node);
        if (node.children) {
          node.children.forEach((child: any) => {
            allSpaces.push(child);
          });
        }
      }

      const filtered = flattened.filter(node => node?.space_type === 'Organization' || node?.space_type ==='SpaceGroup');
      allParents = filtered.sort((a: any, b: any) =>
        a.display_label?.localeCompare(b.display_label),
      );
      this.potentialParentsList$.next((convertKeys.toCamel({ allParents }) as any).allParents);
      this.loadingParentSiteGroup$.next(false);
      this.loadingHierarchy$.next(false);
    }
  }

  selectSite(siteId: string) {
    if (this.sites$.value && this.sites$.value.length > 0) {
      const site = this.sites$.value.find(site => {
        return site.id === siteId;
      });
      this.selectedSite$.next(site);
    }
  }

  async getSite(id: string) {
    try {
      this._loadingSite$.next(true);
      let site = await this.apiService.get(`sites/${id}`);
      this.selectedSiteId$.next(id);
      site = this.transformApiDataForUI(site);
      this.site$.next(site);
      return site;
    } catch (err) {
      console.log(`Could not get site`, err);
      this.setErrorLoadingSite(err);
      throw err;
    }
  }

  async createSite(site: Site, parentId: string) {
    try {
      const createdSite = await this.apiService.post('sites', site);
      const siteId = createdSite.id;
      const dataForRelationship = {
        space_id: parentId,
        related_id: siteId,
      };
      await this.apiService.post('relationships', dataForRelationship);
      return convertKeys.toCamel<Site>(createdSite);
    } catch (err) {
      console.log(`Could not Create Site`, err);
      throw err;
    }
  }

  async deleteSite(siteId: string) {
    const response = await this.apiService.delete(`sites/${siteId}`);
    return convertKeys.toCamel<any>(response);
  }

  async updateSite(site: Site) {
    try {
      const id = site.id;
      const updatedSite = await this.apiService.put(`sites/${id}`, site);
      return convertKeys.toCamel<any>(updatedSite);
    } catch (err) {
      console.log(`Could not Update Site`, err);
      throw err;
    }
  }

  updateSelectedSite(siteId: string) {
    this.selectedSiteId$.next(siteId);
  }

  transformApiDataForUI(site) {
    const convertedSite: Site = convertKeys.toCamel(site);
    const transformData = {
      ...convertedSite,
      displayLabels: site?.display_labels ?? site?.displayLabels,
      descriptions: site.descriptions,
      ecrmId: site.alternate_ids ? site.alternate_ids.ECRM_ID : '',
      siteUseTypes: convertedSite?.ifmaClass.toString(),
      parent: {
        ...convertedSite?.parent,
        displayLabels: site.parent.display_labels,
        descriptions: site.parent.descriptions
      }
    };
    delete transformData.display_labels;
    return transformData;
  }

  findParentId(relationships: any) {
    if (relationships && relationships.length <= 0) {
      return '';
    }
    const parentOrg = relationships.find(relationship => relationship?.related_space?.space_type == 'Organization');
    return parentOrg.related_space.id || '';
  }

  transformFormData(formData: any, alternate_ids?) {
    const convertedSite: any = convertKeys.toSnake(formData);
    const transformData = {
      ...convertedSite,
      ifma_class: parseInt(formData.siteUseTypes) || 5,
      display_labels: formData?.display_labels ?? formData?.displayLabels,
      descriptions: formData?.descriptions,
      alternate_ids: {
        ...alternate_ids,
        ECRM_ID: formData.ecrmId,
      },
    };

    if (transformData.floor_space == '') {
      delete transformData.floor_space;
    }
    if (transformData.alternate_ids.ECRM_ID == '') {
      delete transformData.alternate_ids.ECRM_ID;
    }
    return transformData;
  }

  async getSitesFromParent({ id, spaceType }: { id: string; spaceType: string }) {
    if (!this.loadingHierarchy$.value) {
      let childrenList: Organization[] | SiteGroup[];
      if (spaceType.toLowerCase() === 'organization') {
        childrenList = this.orgHierarchy$.value;
      } else {
        childrenList = await this.atlasPagesService.getSpecificHierarchies(['spacegroup', 'site']);
      }
      const childrenFound = childrenList.filter(e => e.id == id)[0]?.children || [];
      const childrenFiltered = childrenFound?.filter(e => e.core_space_type == "SITE" || e.type == "Site")
      this.childrenList$.next((convertKeys.toCamel({ childrenFiltered }) as any).childrenFiltered);
      return childrenFiltered;
    }
  }

  setChildrenList(value) {
    this.childrenList$.next(value);
  }

  async getSiteList() {
    if (this.isInit) {
      try {
        this.loadingHierarchiesSites$.next(true);
        const site = await this.atlasPagesService.getSpecificHierarchies(['site']);
        this.siteList$.next(site);
        this.loadingHierarchiesSites$.next(false);
      } catch (err) {
        console.log(`Could not load sites`, err);
      }
    }
    this.isInit = false;
  }

  async getRootOrg(hierarchyItem) {
    if (hierarchyItem?.parent === undefined && hierarchyItem?.topology === undefined) {
      return;
    }
    if (hierarchyItem?.parent?.spaceType === 'Organization') {
      return hierarchyItem.parent;
    }
    if (hierarchyItem?.topology?.length && (hierarchyItem?.topology[0]?.space_type === 'Organization' || hierarchyItem?.topology[0]?.spaceType === 'Organization')) {
      return hierarchyItem.topology[0];
    }
    let parentId;
    if (hierarchyItem?.parent?.id) {
      parentId = hierarchyItem.parent.id;
    } else if (hierarchyItem?.parent_id) {
      parentId = hierarchyItem.parent_id;
    } else if (hierarchyItem?.parentId) {
      parentId = hierarchyItem.parentId;
    }
    if (!parentId) {
      return
    }
    const newHierarchyItem = await this.apiService.get(
      `site-groups/${parentId}`,
    );
    return this.getRootOrg(newHierarchyItem);
  }

  setCurrentParentId(id) {
    this.currentParent$.next(id);
  }

  setSelectedSite(siteID) {
    this.selectedSiteId$.next(siteID);
  }

  async setErrorLoadingSite(error) {
    this.errorLoadingSite$.next(error);
  }
}
