import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { CustomersService } from '../shared/services/customers.service';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '../shared/model/locale.model';
import { Site } from '../shared/model/site.model';
import { OrganizationsService, TYPES_DROP_DOWN } from '../shared/services/organizations.service';
import { SitesService } from '../shared/services/sites.service';
import { SiteGroupService } from '../shared/services/site-group.service';
import { ChangeParentParams } from '../shared/model/site-group.model';

const INTEGER_ONLY = /^[0-9]*$/;
const CRM_ID = /^[A-Za-z0-9]{18}$/;
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  ORG_UPDATE_SUCCESS = 'Organization Updated Successfully';
  SITE_UPDATE_SUCCESS = 'Site was updated successfully';
  SITE_GROUP_UPDATE_SUCCESS = 'Site group was updated successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your asset';
  ORG_NOT_UPDATED = 'Oops, there was a problem updating your organization';
  SITE_NOT_UPDATED = 'Oops, There was a problem updating your site';
  SITE_GROUP_NOT_UPDATED = 'Oops, There was a problem updating your site group';
  REQUIRED = 'required';
  CONFLICT = 'There is a data conflict with this Template';
  ORG_SUCCESS = 'Organization Created Successfully';
  ORG_NOT_CREATED = 'Oops, There was a problem creating your organization';
  SITE_NOT_CREATED = 'Oops, There was a problem creating your site';
  SITE_SUCCESS = 'Site was created successfully';
  EDIT_ORG_SUCCESS = 'Organization Updated Successfully';
  isSiteMode = false;
  orgId = '';
  typesDropDown = TYPES_DROP_DOWN;
  readonly mode = 'edit';
  readonly APPPREFIX = 'prt';
  site: Site;
  siteId: string;
  siteGroupId: string;
  isLoadingSite  = false;
  customerId = '';
  subscriptions = [];
  isSubmitting = false;
  isLoadingAsset = false;
  selectedType  = 'ORGANIZATION';
  currentOrg: any;
  currentOrgData: any;
  currentSite = null;
  currentSiteGroup: any;

  siteForm: FormGroup = new FormGroup({
    displayLabels: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    siteUseTypes: new FormControl(''),
    parentId: new FormControl('', Validators.required),
    floorSpace: new FormControl('', Validators.pattern(INTEGER_ONLY)),
    descriptions: new FormControl(''),
    country: new FormControl('', Validators.required),
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl({ value: '', disabled: true }, Validators.required),
    postalCode: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    subIndustry: new FormControl({ value: '', disabled: true }, Validators.required),
    ecrmId: new FormControl('', Validators.pattern(CRM_ID)),
  });

  orgForm: FormGroup = new FormGroup({
    displayLabels: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    parent: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    subIndustry: new FormControl('', Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    legalName: new FormControl('', Validators.required),
    status: new FormControl('ACTIVE', Validators.required),
    ecrmId: new FormControl('', Validators.pattern(CRM_ID)),
    descriptions: new FormControl(''),
  });

  siteGroupForm: FormGroup = new FormGroup({
    displayLabels: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    parentId: new FormControl({ value: '', disabled: false }, Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    status: new FormControl('ACTIVE', Validators.required),
    descriptions: new FormControl(''),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private CustomersService: CustomersService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private sitesService: SitesService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public organizationService: OrganizationsService,
    public siteGroupService: SiteGroupService,
  ) {
    const updateTranslationSub = this.translateService
      .get('customer.organization.notification.updated_successfully')
      .subscribe((result: string) => {
        this.ORG_UPDATE_SUCCESS = result;
        this.SITE_UPDATE_SUCCESS = this.translateService.instant('customer.site.notification.updated_successfully');
        this.SITE_GROUP_UPDATE_SUCCESS = this.translateService.instant(
          'customer.site_group.notification.update_success',
        );
        this.BAD_REQUEST = this.translateService.instant('customer.notification.bad_request');
        this.ORG_NOT_UPDATED = this.translateService.instant('customer.organization.notification.not_updated');
        this.SITE_NOT_UPDATED = this.translateService.instant('customer.site.notification.not_updated');
        this.SITE_GROUP_NOT_UPDATED = this.translateService.instant('customer.site_group.notification.update_failure');
        this.REQUIRED = this.translateService.instant('customer.validation.required');
        this.EDIT_ORG_SUCCESS = this.translateService.instant('organization.notification.updated_successfully');
      });

    const customerConflictSub = this.CustomersService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });

    this.subscriptions = [updateTranslationSub, customerConflictSub];
  }

  ngOnInit() {
    const urlSub = this.route.queryParams.subscribe(async queryParam => {
      this.selectedType = queryParam.type.toUpperCase();
    });
    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        if (this.selectedType == 'ORGANIZATION') {
          this.orgId = params.id;
          this.isLoadingAsset = true;
          await this.organizationService.getOrg(this.orgId);
          this.isLoadingAsset = false;
          this.organizationService.addSelectedOrg(this.orgId);
        } else if (this.selectedType == 'SITE') {
          this.siteId = params.id;
          this.isLoadingSite = true;
          this.sitesService.updateSelectedSite(this.siteId);
          this.sitesService.selectSite(this.siteId);
          this.isLoadingSite = false;
        } else if (this.selectedType == 'SITE_GROUP') {
          this.siteGroupId = params.id;
          this.siteGroupService.getSiteGroup(this.siteGroupId);
        }
      }
    });

    const siteSub = this.sitesService.site$.subscribe(async site => {
      if (site) {
        this.site = site;
        this.currentSite = site;
        this.siteForm.patchValue(site);
      }
    });

    const orgSub = this.organizationService.org$.subscribe(async org => {
      if (org) {
        this.currentOrg = org;
        this.orgForm.patchValue(org);
      }
    });

    const orgSubDetails = this.organizationService.orgDetails$.subscribe(async org => {
      if (org) {
        this.currentOrgData = org;
      }
    });

    const siteGroupSub = this.siteGroupService.siteGroupDetails$.subscribe(siteDetails => {
      if (siteDetails) {
        this.currentSiteGroup = siteDetails;
        this.siteGroupForm.patchValue(siteDetails);
      }
    });
    this.subscriptions = [routeSub, siteSub, urlSub, orgSub, siteGroupSub, orgSubDetails];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  isFormValid() {
    if (this.selectedType === 'ORGANIZATION') {
      return !this.orgForm.valid;
    } else if (this.selectedType === 'SITE') {
      return !this.siteForm.valid;
    } else if (this.selectedType === 'SITE_GROUP') {
      return !this.siteGroupForm.valid;
    }
  }

  getDisplayTypeTitle() {
    let title;
    switch (this.selectedType) {
      case 'ORGANIZATION':
        title = this.currentOrg
          ? this.translateService.instant('organization.edit.page_title') + ' ' + this.currentOrg.displayLabel
          : this.translateService.instant('organization.edit.page_title');
        break;
      case 'SITE':
        title = this.currentSite
          ? this.translateService.instant('site.edit.page_title') + ' ' + this.currentSite.displayLabel
          : this.translateService.instant('site.edit.page_title');
        break;
      case 'SITE_GROUP':
        title = this.currentSiteGroup
          ? this.translateService.instant('site_group.edit.page_title') + ' ' + this.currentSiteGroup.displayLabel
          : this.translateService.instant('site_group.edit.page_title');
        break;
      default:
        break;
    }
    return title;
  }

  onChangeType(type) {
    switch (type) {
      case 'ORGANIZATION':
        this.isSiteMode = false;
        break;
      case 'SITE':
        this.isSiteMode = true;
        break;
      case 'SITE_GROUP':
        this.isSiteMode = false;
        break;
      default:
        this.isSiteMode = false;
        break;
    }
  }

  getDisplayTypeSubtitle() {
    let subtitle;
    switch (this.selectedType) {
      case 'ORGANIZATION':
        subtitle = this.translateService.instant('organization.edit.subtitle');
        break;
      case 'SITE':
        subtitle = this.translateService.instant('site.edit.subtitle');
        break;
      case 'SITE_GROUP':
        subtitle = this.translateService.instant('site_group.edit.subtitle');
        break;
      default:
        break;
    }
    return subtitle;
  }

  async handleSubmit() {
    if (!this.orgForm.valid && !this.siteForm.valid && !this.siteGroupForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.isSubmitting = true;
        let response = null;
        if (this.selectedType == 'ORGANIZATION') {
          const parent = this.organizationService.getParent();
          const transformedData = this.organizationService.transformFormData(
            this.orgForm.getRawValue(),
            this.currentOrg.alternate_ids,
          );
          response = await this.organizationService.updateOrganization(transformedData, parent, this.currentOrg.id);
          this.ngxDeeplinkerService.returnHandler({
            appPrefix: this.APPPREFIX,
            callbackValue: response.id,
          });
          this.messageService.setSuccess(this.EDIT_ORG_SUCCESS);
          this.messageService.setSuccess(this.EDIT_ORG_SUCCESS);
          this.organizationService.initPageLoader();
          this.organizationService.setRefreshingList(true);
          this.organizationService.isInit = true;
          this.organizationService.getOrgs();
        } else if (this.selectedType === 'SITE') {
          const site = { ...this.siteForm.getRawValue() };
          const transformedData = this.sitesService.transformFormData(site, this.site.alternate_ids);
          response = await this.sitesService.updateSite({ ...transformedData, id: this.siteId });
          this.messageService.setSuccess(this.SITE_UPDATE_SUCCESS);
        } else if (this.selectedType === 'SITE_GROUP') {
          const prevChildrenList = this.siteGroupService.getInitialState() || [];
          const prevSiteGroup = { ...this.currentSiteGroup };
          const siteGroup = { ...this.siteGroupForm.getRawValue() };
          const transformedData = this.siteGroupService.transformFormData(siteGroup);
          const childrenSelected = this.siteGroupService.getSitesSelected() || [];
          let childrenResponse: { successes: any[]; failures: any[] } = { successes: [], failures: [] };
          let paramsChangeParent: ChangeParentParams;
          let children: any[] = [];
          try {
            response = await this.siteGroupService.updateSiteGroup({ ...transformedData, id: this.siteGroupId });

            // Call relationships for added children
            if (childrenSelected.length > 0) {
              const prevChildrenIds = prevChildrenList.map(item => item.id);
              children = childrenSelected.filter(item => !prevChildrenIds.includes(item.id));
              paramsChangeParent = {
                children,
                currentParentId: siteGroup.parentId,
                newParentId: this.siteGroupId,
              };
              const { successes, failures } = await this.siteGroupService.changeParent(paramsChangeParent);
              childrenResponse = { ...childrenResponse, successes, failures };
            }

            // Call relationships for removed children
            if (prevChildrenList.length > 0) {
              const childrenSelectedIds = childrenSelected.map(item => item.id);
              children = prevChildrenList.filter(item => !childrenSelectedIds.includes(item.id));
              paramsChangeParent = {
                children,
                currentParentId: this.siteGroupId,
                newParentId: siteGroup.parentId,
              };
              const { successes, failures } = await this.siteGroupService.changeParent(paramsChangeParent);
              childrenResponse = { ...childrenResponse, successes, failures };
            }

            if (childrenResponse && childrenResponse?.failures.length === 0) {
              this.messageService.setSuccess(this.SITE_GROUP_UPDATE_SUCCESS);
            } else {
              this.messageService.setError(this.SITE_GROUP_NOT_UPDATED);
            }
          } catch (e) {
            console.log(e);
            throw e;
          }
        }
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        setTimeout(async () => {
          await this.sitesService.refetchOrgs();
          void this.router.navigate([`details/${response.id}/view`], {
            queryParams: {
              type: this.selectedType?.toLowerCase(),
            },
          });
        }, 2000);
      } catch (e) {
        console.log(e);
        let errorMessage = '';
        if (e.error && e.error.message) {
          errorMessage = e.error.message;
        } else {
          errorMessage = 'ERR_BAD_REQUEST';
        }
        if (this.selectedType == 'ORGANIZATION') {
          this.messageService.setError(this.ORG_NOT_UPDATED);
        } else if (this.selectedType === 'SITE') {
          this.messageService.setError(this.SITE_NOT_UPDATED);
        } else {
          this.messageService.setError(this.SITE_GROUP_NOT_UPDATED);
        }
      }
      this.isSubmitting = false;
    }
  }

  openDeleteDialog(): void {
    const dialogConfig = new MatDialogConfig();
    let data = {} as any;
    switch (this.selectedType) {
      case 'SITE':
        data = this.currentSite;
        break;
      case 'SITE_GROUP':
        data = this.currentSiteGroup;
        break;
      case 'ORGANIZATION':
        data = this.currentOrg;
        break;
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      id: data.id,
      displayLabel: data.displayLabel,
      spaceType: data.spaceType,
      type: this.selectedType,
      node: data
    };
    dialogConfig.width = '500px';
    this.dialog.open(DeleteDialogComponent, dialogConfig);
  }

  disableDelete() {
    let data;
    switch (this.selectedType) {
      case 'SITE_GROUP':
        return false;
      case 'SITE':
        data = this.currentSite;
        break;
      case 'ORGANIZATION':
        data = this.currentOrgData;
        break;
    }
    if (data) {
      return data.relationships.some(relation => relation.direction == 'OUTGOING');
    }
    return true;
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX });
    const id =
      this.selectedType === 'ORGANIZATION' ? this.orgId : this.selectedType === 'SITE' ? this.siteId : this.siteGroupId;
    this.router.navigate([`details/${id}/view`], {
      queryParams: {
        type: this.selectedType?.toLowerCase(),
      },
    });
  }
}
