<div class="app-customer-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>edit</mat-icon>
                </div>
                <h1 class="ellipsis" data-automation="edit-title">
                    {{ getDisplayTypeTitle() }}
                </h1>
                <p class="desktop-only subtitle" data-automation="edit-subtitle">
                    {{ getDisplayTypeSubtitle() }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button class="icon-btn x-btn-secondary" color="gray" (click)="handleCancel()" data-automation="cancel">
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'customer.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                data-automation="delete"
                [disabled]="disableDelete()"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'customer.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                (click)="handleSubmit()"
                data-automation="save"
                [disabled]="isFormValid()"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'customer.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <form #createForm="ngForm" data-app-prefix="prt" class="customer-form">
        <div class="type-selector-container">
            <mat-form-field class="column" appearance="outline" floatLabel="always">
                <mat-label>{{ 'customer.create.type' | translate }}</mat-label>
                <mat-select
                    id="type"
                    name="type"
                    #type="ngModel"
                    [(ngModel)]="selectedType"
                    data-automation="type"
                    data-url-persist
                    (selectionChange)="onChangeType($event.value)"
                    [value]="selectedType"
                    disabled="true"
                >
                    <mat-option
                        *ngFor="let type of typesDropDown"
                        [disabled]="type.name == 'SITE GROUP'"
                        [value]="type.name"
                        id="type{{ type.name }}"
                        >{{ type.display_label | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container [ngSwitch]="selectedType">
                <ng-container *ngSwitchCase="'ORGANIZATION'">
                    <app-org [mode]="mode" [orgForm]="orgForm"></app-org>
                </ng-container>
                <ng-container *ngSwitchCase="'SITE'">
                    <app-site [mode]="mode" [siteForm]="siteForm"></app-site>
                </ng-container>
                <ng-container *ngSwitchCase="'SITE_GROUP'">
                    <app-site-group [mode]="mode" [siteGroupForm]="siteGroupForm"></app-site-group>
                </ng-container>
            </ng-container>
        </div>
    </form>
</div>
