import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable()
export class EcrmService {
    checkFlexTranslation$: Subject<boolean>;

    constructor(private apiService: ApiService) {
        this.checkFlexTranslation$ = new Subject<boolean>();
    }

    async checkTranslation(ecrmId: string, flexId: string) {
        try {
            const check = await this.apiService.get(`ecrm/${ecrmId}`);

            if ((check.length === 0) || (check.length !== 0 && check[0]?.to?.id === flexId)) {
                this.checkFlexTranslation$.next(true);
            } else {
                this.checkFlexTranslation$.next(false);
            }
        } catch (e) {
            this.checkFlexTranslation$.next(false);
            console.log('Unable to get flexId - EcrmService', e);
        }
    }
}
