<div class="content">
  <div class="wrapper-1">
    <div class="wrapper-2" *ngIf="!error">
      <h1 data-automation="thankText"> {{'customer.thanks' | translate}} !</h1>
      <p>{{'customer.auth_thanks' | translate}} !</p>
      <br/>
    </div>
    <div class="wrapper-2" *ngIf="error">
      <h1 data-automation="errorText"> {{'customer.error' | translate}} !</h1>
      <p>{{ 'customer.error.message' | translate }}</p>
    </div>
  </div>
</div>
