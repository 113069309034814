import { Component, OnInit } from '@angular/core';
import { IconDefinition } from 'ngx-tree-selector';
import { ActivatedRoute } from '@angular/router';
import { LocalesService } from '../shared/services/locales.service';
import { SitesService } from '../shared/services/sites.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale } from '../shared/model/locale.model';
import { ORG_ICON_DEFINITION } from '../shared/model/nodeType.model';
import { TimezonesService } from '../shared/services/timezones.service';
import { SiteUseTypesService } from '../shared/services/site-use-types.service';
import { Subscription } from 'rxjs';
import { Industry } from '../shared/model/industry';
import { SubIndustry } from '../shared/model/sub-industry';
import { filter } from 'rxjs';

@Component({
  selector: 'app-view-site',
  templateUrl: './view-site.component.html',
  styleUrls: ['./view-site.component.scss'],
})
export class ViewSiteComponent implements OnInit {
  siteId = '';
  siteData: any;
  sites: any[] = [];
  orgs: any[] = [];

  selectedOrgs: any;
  useType: any[];

  timezones: Timezone[];
  locales: Locale[];
  iconDefinitions: IconDefinition = {
    ...ORG_ICON_DEFINITION,
  };

  readonly mode = 'view';
  loadingHierarchiesSites: boolean;
  private subscriptions: Subscription[] = [];
  industries: Industry[];
  subIndustries: SubIndustry[];
  currentParent: string;
  constructor(
    private sitesService: SitesService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private organizationsService: OrganizationsService,
    private siteUseTypeService: SiteUseTypesService,
    private route: ActivatedRoute,
  ) {
    this.timezonesService.setTimezones();

    this.timezones = timezonesService.timezones;

    const routeSub = this.route.params.pipe(filter((p: any) => p.id)).subscribe(params => {
      const type = this.route.queryParams['value']['type'].toUpperCase();
      if (params.id && type === 'SITE') {
        this.siteId = params.id;
        this.sitesService.getSite(this.siteId);
      }
    });
    this.subscriptions = [routeSub];
  }

  ngOnInit(): void {
    this.loadDynamicLists();
    const siteUseTypesSub = this.siteUseTypeService.siteUseTypes$.subscribe(useType => {
      if (useType) {
        this.useType = useType;
      }
    });

    const localesSub = this.localesService.locales$.subscribe(locales => {
      if (locales) {
        this.locales = locales;
      }
    });

    //GETTING ORGS HIERARCHIES
    const orgsSub = this.organizationsService.orgs$.subscribe(orgs => {
      this.orgs = orgs;
    });

    //GETTING SITES HIERARCHIES
    const siteListSub = this.sitesService.siteList$.subscribe(sites => {
      if (sites.length > 0) {
        this.sites = sites;
      } else {
        this.sites = [];
      }
    });

    //GETTING CURRENT SITE DETAIL TO LOAD THE VIEW
    const siteDetailsSub = this.sitesService.site$.subscribe(async data => {
      if (data) {
        this.siteData = data;
        const rootOrg = await this.sitesService.getRootOrg(data);
        this.organizationsService.addSelectedOrg(rootOrg.id);
      }
    });

    const loadingHierarchiesSitesSub = this.sitesService.loadingHierarchiesSites$.subscribe(loading => {
      this.loadingHierarchiesSites = loading;
    });

    const currentParentOrgSub = this.sitesService.currentParent$.subscribe(currentParentOrg => {
      this.currentParent = currentParentOrg;
    });
    const selectedOrgsSub = this.organizationsService.selectedOrgs$.subscribe(orgs => {
      this.selectedOrgs = orgs;
    });

    this.subscriptions = [
      ...this.subscriptions,
      siteUseTypesSub,
      localesSub,
      selectedOrgsSub,
      orgsSub,
      siteListSub,
      siteDetailsSub,
      loadingHierarchiesSitesSub,
      currentParentOrgSub,
    ];
  }

  getLocaleDisplayLabel(): string {
    if (this.locales && this.locales.length > 0 && this.siteData && this.siteData.defaultLocale) {
      return this.locales.find(locale => locale.localeName === this.siteData.defaultLocale).displayLabel;
    }
    return '-';
  }
  getTimezoneDisplayLabel(): string {
    if (this.timezones && this.timezones.length > 0 && this.siteData && this.siteData?.timezone) {
      return this.timezones.find(
        timezone => timezone.timezoneName.toUpperCase() === this.siteData.timezone.toUpperCase(),
      )?.displayLabel;
    }
    return '-';
  }

  getIndustryDisplayLabel(): string {
    if (this.siteData && this.siteData.industry) {
      return this.textConverter(this.siteData.industry);
    }
    return '-';
  }

  getSubindustryDisplayLabel(): string {
    if (this.siteData && this.siteData.subIndustry) {
      return this.textConverter(this.siteData.subIndustry);
    }
    return '-';
  }
  getFloorSpace() {
    if (this.siteData && this.siteData.floorSpace) {
      return this.siteData.floorSpace;
    }
    return '-';
  }
  getPrimaryAddress() {
    if (this.siteData && this.siteData.address1) {
      return this.siteData.address1;
    }
    return '-';
  }
  getSecondaryAddress() {
    if (this.siteData && this.siteData.address2) {
      return this.siteData.address2;
    }
    return '-';
  }
  getPostalCode() {
    if (this.siteData && this.siteData.postalCode) return this.siteData.postalCode;
    return '-';
  }
  getCity() {
    if (this.siteData && this.siteData.city) return this.siteData.city;
    return '-';
  }
  getState() {
    if (this.siteData && this.siteData.state) return this.siteData.state;
    return '-';
  }
  getCountryDisplayLabel(): string {
    if (this.siteData && this.siteData.country) {
      return this.siteData.country;
    }
    return '-';
  }
  getECRMId() {
    if (this.siteData && this.siteData.ecrmId && this.siteData.ecrmId) {
      return this.siteData.ecrmId;
    }
    return '-';
  }
  getUseType() {
    if (this.useType.length > 0 && this.siteData && this.siteData.ifmaClass) {
      return this.useType.find(useType => useType.id == this.siteData.ifmaClass)?.displayLabel;
    }
    return '-';
  }

  getParentDisplayLabel(): string {
    return this.siteData.parent.displayLabel;
  }

  private async loadDynamicLists() {
    this.sitesService.getSiteList();
    this.organizationsService.getOrgs();
    this.localesService.setLocales();
    this.siteUseTypeService.setSiteUseTypes();
  }

  getDescription(): string {
    if (this.siteData && this.siteData.description) {
      return this.siteData.description;
    }
    return '-';
  }

  textConverter(text: string): string {
    const wordArray = text.split('_');
    const convertedArray = wordArray.map(word => {
      return word[0] + word.slice(1).toLowerCase();
    });
    return convertedArray.join(' ');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
