import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ServiceAccountsService } from '../shared/services/service-accounts.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { GlobalNavService} from 'ngx-global-nav';
import { Subscription } from 'rxjs';
import { ServiceAccount } from '../shared/model/service-account';
import { Utility } from '../shared/model/utility.model';
import { ProfileMeta } from '../shared/model/profile-meta.models';
import { ConedService } from '../shared/services/coned.service';
import { ConedCredentials } from '../shared/model/coned-credentials';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

const SERVICE_ACCOUNT_STATUSES_WHITELIST = [
  "UNAUTHORIZED",
  "AUTHORIZED",
  "PENDING"
]

const SERVICE_ACCOUNT_UTILITY_WHITELIST =[
  "CONSOLIDATED_EDISON_COMPANY_OF_NEW_YORK_CONED"
]

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})

export class AuthorizationComponent implements OnInit {
  utilityName: string;
  loadingCustomerAdmin = true;
  serviceAccounts: ServiceAccount[] = [];
  serviceAccountsMock: { serviceName: string; loa_status: string; }[];
  AUTHORIZATION_FAILED = "There was an error trying to authorize this service account";
  AUTHORIZATION_SUCCESS = "Authorization Submitted Successfully";
  subscriptions: Subscription[] = [];
  currentPage = "authorization";
  updateServiceAccounts = true;
  parentUrl: string;
  userDefaultLocale: string;
  loading = false;
  authButton = true;
  noServiceAccountsAvailable = false;
  isRetrievingServiceAccounts = true;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private route: ActivatedRoute,
    private serviceAccountsService:ServiceAccountsService,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    private messageService: GlobalAlertService,
    private globalNavService: GlobalNavService,
    private conedService: ConedService,
    ) {
      this.matIconRegistry.addSvgIcon('noData', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/no-data.svg'));
    const userDataSub = this.globalNavService.userData$.subscribe((userDataInfo) => {
      if (userDataInfo) {
        this.userDefaultLocale = userDataInfo.preferences.default_locale;
      }
    })
    const metaDataSub = this.globalNavService.metaData$.subscribe((metaDataInfo:ProfileMeta) => {
      let conedUtility:Utility[];
      if (metaDataInfo && metaDataInfo.utilities?.length > 0) {
        conedUtility = metaDataInfo.utilities.filter((utility)=>SERVICE_ACCOUNT_UTILITY_WHITELIST.includes(utility.name))
        this.utilityName = conedUtility[0].display_labels[this.userDefaultLocale];
      }
    })

    this.translateService.get('customer.authorization.notification.updated_successfully').subscribe((result: string) => {
      this.AUTHORIZATION_FAILED = this.translateService.instant('customer.authorization.notification.bad_request');
      this.AUTHORIZATION_SUCCESS = this.translateService.instant('customer.authorization.notification.updated_successfully');
    });

    const routeUrlSub = this.route.url.subscribe(url => {
      this.currentPage = url[0].path
    });
   
    const serviceAccountsSub = this.serviceAccountsService.serviceAccounts$.subscribe((serviceAccounts)=>{
      if(serviceAccounts.length > 0) {
         this.serviceAccountsService.setServiceAccount(serviceAccounts);
        this.serviceAccounts = serviceAccounts.filter((serviceAccount) => SERVICE_ACCOUNT_STATUSES_WHITELIST.includes(serviceAccount.loaStatus) && SERVICE_ACCOUNT_UTILITY_WHITELIST.includes(serviceAccount.utilityName));
        this.isRetrievingServiceAccounts = false;
        const status = (serviceAccount) => serviceAccount.loaStatus  === "UNAUTHORIZED" || serviceAccount.loaStatus  === "PENDING";
        const isPendingOrUnauthorized = this.serviceAccounts.some(status);
        if(this.currentPage == "thank-you-page" && isPendingOrUnauthorized ) {
          if (this.updateServiceAccounts) {
            this.handleThankYou();
            this.updateServiceAccounts = false;
          }
        }
      } else {
        //no service accounts available for this user
        this.isRetrievingServiceAccounts = false;
        this.noServiceAccountsAvailable = true;
      }
      this.loadingCustomerAdmin = false;
      this.getButtonAvailability();
     })

    const loadingSub = this.serviceAccountsService.loadingServiceAccount$.subscribe((loading) => {
      this.loadingCustomerAdmin = loading;
    });
    const isUpdatedSub = this.serviceAccountsService.updateFinished$.subscribe( (data)=> {
       if (data.isUpdated) {
         this.loading = false;
         if (data.status === "AUTHORIZED") {
          this.messageService.setSuccess(this.AUTHORIZATION_SUCCESS);
         }
       } else {
        this.loading = true;
       }
     });
     const hasUpdatedFailed = this.serviceAccountsService.updateFailed$.subscribe( (hasUpdatedFailed)=> {
      if (hasUpdatedFailed) {
        this.loading = false;
        this.messageService.setError(this.AUTHORIZATION_FAILED);
      }
    })

     this.subscriptions.push(
      ...[
        routeUrlSub,
        serviceAccountsSub,
        userDataSub,
        metaDataSub,
        isUpdatedSub,
        hasUpdatedFailed,
        loadingSub,
      ],
    );
  }
  
  getServiceAccounts() {
    this.loadingCustomerAdmin = true;
    this.serviceAccountsService.getServiceAccounts();
  }

  handleThankYou() {
    if (this.serviceAccounts) {
      this.loadingCustomerAdmin = true;
    }
  }

  ngOnInit() {
    this.getServiceAccounts();
  }

  async openAuth(){
    const links: ConedCredentials = await this.conedService.credentials;
    window.location.href = links?.conedRedirectUrl;
  }

  async openTerms(){
    const links: ConedCredentials = await this.conedService.credentials;
    window.open(links?.conedTermsUrl);
  }

  getButtonAvailability(){
    const status = (serviceAccount) => serviceAccount.loaStatus  === "UNAUTHORIZED" || serviceAccount.loaStatus  === "PENDING";
    const isPendingOrUnauthorized = this.serviceAccounts.some(status);
    if(this.serviceAccounts.length > 0 && isPendingOrUnauthorized){
      this.authButton = false;
    } else {
      this.authButton = true;
    }
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
