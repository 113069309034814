<ng-container *ngIf="noOrgs && !loading">
    <div class="empty-page">
        <mat-icon class="material-icons-outlined">warning_amber</mat-icon>
        <h2>{{ 'customer.no_orgs_selected_title' | translate }}</h2>
        <span>{{ 'customer.no_orgs_selected_subtitle_1' | translate }}</span>
        <br />
        <span>{{ 'customer.no_orgs_selected_subtitle_2' | translate }}</span>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="empty-page">
        <mat-spinner diameter="60"></mat-spinner>
        <h2>{{ 'organization.loading' | translate }}...</h2>
    </div>
</ng-container>
