import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { AtlasPagesService, Type } from 'atlas-pages-loader';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { Organization } from '../model/organization.model';
import * as convertKeys from 'convert-keys';
@Injectable()
export class OrganizationsService {
  atlasPagesService: AtlasPagesService;
  isInit = true;
  refreshingList = false;
  readonly forcedOrg = new BehaviorSubject<any>(null);
  readonly orgs$ = new BehaviorSubject<Type[]>(null);
  readonly org$ = new Subject<Type[]>();
  selectedOrgs$ = new BehaviorSubject<string[]>([]);
  private readonly _loadingOrgs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly adminGroups$ = new BehaviorSubject<Type[]>(null);
  readonly currentOrg$ = new BehaviorSubject<any>(null);
  readonly orgDetails$ = new Subject<Type>();
  readonly errorLoadingOrg$ = new BehaviorSubject<any>(null);
  readonly orgLoader$ = new BehaviorSubject<any>(true);
  readonly parent$ = new BehaviorSubject<any>(null);

  constructor(
    private apiService: ApiService,
    private contextSelectorService: ContextSelectorService,
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) {
    this.contextSelectorService.currentContext$.subscribe((contexts: Context[]) => {
      this.currentOrg$.next(contexts[0]);
    });
    this.atlasPagesService = new AtlasPagesService(
      cookieService.getMarketsMock(),
      cookieService.getEnocSession(),
      httpClient,
    );
  }

  async setOrgDetails(orgDetails) {
    this.orgDetails$.next(convertKeys.toCamel(orgDetails));
  }
  getIsInit() {
    return this.isInit;
  }

  loadingOrg() {
    this.orgLoader$.next(true);
  }
  setIsInt(value: boolean) {
    this.isInit = value;
  }

  forceReloadNoCached() {
    this.isInit = true;
    this.atlasPagesService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  setRefreshingList(value) {
    this.refreshingList = value;
  }

  getRefreshingList() {
    return this.refreshingList;
  }

  initPageLoader() {
    this.atlasPagesService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  getLoadingOrgs() {
    return this._loadingOrgs$.asObservable();
  }

  async getOrgs() {
    if (this.isInit) {
      this.isInit = false;
      try {
        this._loadingOrgs$.next(true);
        const orgs = await this.atlasPagesService.getSpecificHierarchies(['organization']);
        this.orgs$.next(orgs);
        this._loadingOrgs$.next(false);
      } catch (err) {
        console.log(`Could not load Organizations.`, err);
      }
    }
  }

  async getOrg(orgId) {
    try {
      const org = await this.apiService.get(`organizations/${orgId}`);
      const parent = org.relationships.filter(ele => ele.direction === 'INCOMING')[0].related_space;
      const result: any = convertKeys.toCamel(org);
      result.parent = parent.id;
      this.parent$.next(result.parent);
      delete result.relationships;
      this.org$.next({ ...result, displayLabels: org.display_labels, descriptions: org.descriptions });
      return result;
    } catch (err) {
      console.log(`Could not load the org.`, err);
    }
  }

  getParent() {
    return this.parent$.value;
  }

  async getAdminGroups() {
    try {
      const adminGroups = await this.atlasPagesService.getSpecificHierarchies(['admingroup']);
      this.adminGroups$.next(adminGroups);
    } catch (err) {
      console.log(`Could not load Admin group.`, err);
    }
  }

  async createOrganization(org: Organization): Promise<Organization> {
    try {
      const dto = convertKeys.toSnake<any>(org);

      dto.display_labels = org.displayLabels;
      dto.descriptions = org.descriptions;
      dto.subindustry = org.subIndustry;
      if (org.ecrmId) {
        dto.alternate_ids = {};
        dto.alternate_ids.ECRM_ID = org.ecrmId;
      }
      delete dto['crm_id'];
      delete dto['supported_locales'];
      delete dto['parent'];
      delete dto['sub_industry'];
      const response = await this.apiService.post('organizations', dto);

      const relationships = {
        related_id: response.id,
        space_id: org.parent,
      };
      await this.apiService.post('relationships', relationships);
      return convertKeys.toCamel<any>(response);
    } catch (err) {
      console.log(`Could not Create Organization`, err);
      throw err;
    }
  }

  async updateOrganization(org: Organization, parent: string, orgId: string): Promise<Organization> {
    try {
      const response = await this.apiService.put(`organizations/${orgId}`, org);
      if (parent !== org.parent) {
        await this.apiService.delete(`relationships/${parent}/${response.id}`);
        const relationships = {
          related_id: response.id,
          space_id: org.parent,
        };
        await this.apiService.post('relationships', relationships);
      }
      return convertKeys.toCamel<any>(response);
    } catch (err) {
      console.log(`Could not Updated Organization`, err);
      throw err;
    }
  }

  async getOrganizationById(organizationId: string) {
    try {
      const orgDetails = await this.apiService.get(`organizations/${organizationId}`);
      this.setOrgDetails(orgDetails);
    } catch (err) {
      const error: any = err;
      console.error('Could not load organization. Error: ', error.message);
      this.setErrorLoadingOrg(err);
    }
  }

  removeDeletedOrg(id) {
    const selectedOrgs = this.selectedOrgs$.value.filter(orgId => {
      return orgId !== id;
    });
    if (selectedOrgs.length === 0) {
      const firstOrgId = this.orgs$.value[0].id;
      selectedOrgs.push(firstOrgId);
    }
    this.selectedOrgs$.next(selectedOrgs);
  }

  changeSelection(orgs: any[]) {
    this.selectedOrgs$.next(orgs);
  }

  addSelectedOrg(orgId: string) {
    if (!this.selectedOrgs$.value.includes(orgId)) {
      this.selectedOrgs$.next([...this.selectedOrgs$.value, orgId]);
    }
  }

  async setErrorLoadingOrg(error) {
    this.errorLoadingOrg$.next(error);
  }

  async setAdminGroups(adminGroups) {
    this.adminGroups$.next((convertKeys.toCamel({ adminGroups }) as any).adminGroups);
  }

  async deleteOrg(id: string) {
    const response = await this.apiService.delete(`organizations/${id}`);
    return convertKeys.toCamel<any>(response);
  }

  transformFormData(formData: any, alternate_ids: any) {
    const transformData = {
      ...formData,
      alternate_ids: {
        ...alternate_ids,
        ECRM_ID: formData.ecrmId,
      },
      display_labels: formData.displayLabels,
      default_locale: formData.defaultLocale,
      subindustry: formData.subIndustry,
      legal_name: formData.legalName,
    };

    delete transformData.ecrmId;
    delete transformData.legalName;
    delete transformData.displayLabels;
    delete transformData.subIndustry;
    delete transformData.supportedLocales;
    delete transformData.defaultLocale;
    if (transformData.alternate_ids.ECRM_ID == '') {
      delete transformData.alternate_ids.ECRM_ID;
    }
    if (transformData.descriptions == '') {
      delete transformData.descriptions[transformData.default_locale];
    }
    if (transformData.status == '') {
      delete transformData.status;
    }
    if (transformData.timezone == '') {
      delete transformData.timezone;
    }
    return transformData;
  }
}

export const TYPES_DROP_DOWN = [
  {
    name: 'ORGANIZATION',
    display_label: 'customer.type.organization',
  },
  {
    name: 'SITE',
    display_label: 'customer.type.site',
  },
  {
    name: 'SITE_GROUP',
    display_label: 'customer.type.site_group',
  },
];
