import { Component, OnInit } from '@angular/core';
import { IconDefinition } from 'ngx-tree-selector';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalesService } from '../shared/services/locales.service';
import { SitesService } from '../shared/services/sites.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale } from '../shared/model/locale.model';
import { ORG_ICON_DEFINITION } from '../shared/model/nodeType.model';
import { TimezonesService } from '../shared/services/timezones.service';
import { Subscription } from 'rxjs';
import { SiteGroupService } from '../shared/services/site-group.service';
import { SidenavService } from '../shared/services/sidenav.service';

@Component({
  selector: 'app-view-site-group',
  templateUrl: './view-site-group.component.html',
  styleUrls: ['./view-site-group.component.scss'],
})
export class ViewSiteGroupComponent implements OnInit {
  siteGroupId = '';
  siteData: any;
  sites: any[] = [];
  id = undefined;
  loadingSite = false;
  loadingChildren = false;
  loadingFromChildrenList: boolean;
  selectedOrgs: any[];
  timezones: Timezone[];
  locales: Locale[];
  iconDefinitions: IconDefinition = {
    ...ORG_ICON_DEFINITION,
  };
  sitesSourceList = [];
  readonly mode = 'view';
  loadingHierarchiesSites: boolean;
  private subscriptions: Subscription[] = [];
  loadingParent = true;

  constructor(
    private siteGroupService: SiteGroupService,
    private sitesService: SitesService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private organizationsService: OrganizationsService,
    private route: ActivatedRoute,
    private router: Router,
    private sidenavService: SidenavService,
  ) {
    this.timezonesService.setTimezones();

    this.timezones = timezonesService.timezones;

    const loadingChildrenSub = this.sitesService.loadingHierarchy$.subscribe(loading => {
        this.loadingChildren = loading;
    });

    const routeSub = this.route.params.subscribe(async params => {
      const type = this.route.queryParams['value']['type'].toUpperCase();
      if (params.id && type === 'SITE_GROUP') {
        this.siteGroupId = params.id;
        this.siteGroupService.getSiteGroup(this.siteGroupId);
      }
    });
    this.subscriptions = [routeSub, loadingChildrenSub];
  }

  ngOnInit(): void {
    this.loadDynamicLists();
    const localesSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
    });

    const siteDetailsSub = this.siteGroupService.siteGroupDetails$.subscribe(async data => {
      if (data) {
        this.siteData = data;
        this.sitesSourceList = []
        const { id, spaceType } = this.siteData;
        this.sitesService.getSitesFromParent({ id, spaceType });
        this.loadingFromChildrenList = false;
        const rootOrg = await this.sitesService.getRootOrg(data);
        this.organizationsService.addSelectedOrg(rootOrg.id);
      }
    });

    const orgHierarchySub = this.sitesService.orgHierarchy$.subscribe(orgHierarchy => {
      if (this.siteData && orgHierarchy) {
        this.sitesSourceList = []
        const { id, spaceType } = this.siteData;
        this.sitesService.getSitesFromParent({ id, spaceType });
      }
    });

    const childrenListSub = this.sitesService.childrenList$.subscribe(list => {
      if (list) {
        if (list.length > 0) {
          this.sitesSourceList = list;
        }
      }
    });

    this.subscriptions = [...this.subscriptions, localesSub, siteDetailsSub, childrenListSub, orgHierarchySub];
  }

  getDefaultLocale(): string {
    if (this.locales && this.locales.length > 0 && this.siteData && this.siteData?.defaultLocale) {
      return this.locales.find(locale => locale.localeName === this.siteData?.defaultLocale).displayLabel;
    }
    return '-';
  }

  getTimezoneDisplayLabel(): string {
    if (this.timezones && this.timezones.length > 0 && this.siteData && this.siteData?.timezone) {
      return this.timezones.find(
        timezone => timezone.timezoneName.toUpperCase() === this.siteData?.timezone.toUpperCase(),
      )?.displayLabel;
    }
    return '-';
  }

  getCountryDisplayLabel(): string {
    if (this.siteData && this.siteData?.country) {
      return this.siteData.country;
    }
    return '-';
  }

  getParentDisplayLabel(): string {
    this.loadingParent = true
    if (this.siteData && this.siteData?.parentId) {
      return this.siteData.topology[0].displayLabel
    }
    return '-';
  }

  private async loadDynamicLists() {
    this.sitesService.getOrgHierarchies();
    this.organizationsService.getOrgs();
    this.localesService.setLocales();
  }

  getDescription(): string {
    if (this.siteData && this.siteData?.description) {
      return this.siteData.description;
    }
    return '-';
  }

  getStatus(): string {
    if (this.siteData && this.siteData?.status) {
      if (this.siteData.status == 'ACTIVE') {
        return 'Active'
      }
      if (this.siteData.status == 'INACTIVE') {
        return 'Inactive'
      }
    }
    return '-';
  }

  onSelect(dataItem) {
    const { id, spaceType } = dataItem;
    if (this.router.url.includes(id)) return;
    this.sitesSourceList = [];
    if (spaceType === 'Spacegroup') {
      this.loadingFromChildrenList = true;
      this.organizationsService.loadingOrg();
      this.router.navigate([`details/${id}/view`], {
        queryParams: { type: spaceType === 'Spacegroup' ? 'site_group' : spaceType },
      });
      this.sidenavService.closeIfMobile();
      return;
    }
    if (spaceType === 'Site') {
      this.organizationsService.loadingOrg();
      this.router.navigate([`details/${id}/view`], { queryParams: { type: spaceType } });
      this.sidenavService.closeIfMobile();
    }
    if (spaceType !== 'Organization') return;
    this.sidenavService.closeIfMobile();
    this.organizationsService.loadingOrg();
    const url = `details/${id}/view`;
    this.router.navigate([url], { queryParams: { type: spaceType } });
  }

  getIconByType(dataItem) {
    if (this.iconDefinitions[dataItem.spaceType] && this.iconDefinitions[dataItem.spaceType].name) {
      return this.iconDefinitions[dataItem.spaceType].name;
    }
  }

  ngOnDestroy() {
    this.sitesService.setChildrenList([]);
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
