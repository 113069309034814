<div>
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingHeaderData">people</mat-icon>
                    <mat-spinner *ngIf="loadingHeaderData" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="view-title" class="ellipsis" data-automation="name" title="{{ customer.displayLabel }}">
                    <ng-container *ngIf="!loadingHeaderData">
                        <ng-container *ngIf="type === 'SITE'">
                            {{ siteData?.displayLabel }}
                        </ng-container>
                        <ng-container *ngIf="type === 'ORGANIZATION'">
                            {{ organizationData?.displayLabel }}
                        </ng-container>
                        <ng-container *ngIf="type === 'SITE_GROUP'">
                            {{ siteGroupData?.displayLabel }}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="loadingHeaderData">
                        {{ 'customer.loading' | translate }}
                    </ng-container>
                </h1>
                <p class="subtitle" data-automation="subtitle">
                    <ng-container *ngIf="type === 'SITE'">
                        {{ 'site.view.subtitle' | translate }}
                    </ng-container>
                    <ng-container *ngIf="type === 'ORGANIZATION'">
                        {{ 'organization.view.subtitle' | translate }}
                    </ng-container>
                    <ng-container *ngIf="type === 'SITE_GROUP'">
                        {{ 'site_group.view.subtitle' | translate }}
                    </ng-container>
                </p>
            </div>
        </div>
        <div class="right-column">
            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                data-automation="delete"
                [disabled]="disableDelete()"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'customer.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleEdit()"
                data-automation="edit"
                [disabled]="loadingHierarchy"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text"> {{ 'customer.button.edit' | translate }}</span>
            </button>
        </div>
    </header>
    <form #view="ngForm">
        <div class="form-group">
            <ng-container [ngSwitch]="type">
                <ng-container *ngSwitchCase="'ORGANIZATION'">
                    <app-org [mode]="'view'" [organization]="organizationData"></app-org>
                </ng-container>
                <ng-container *ngSwitchCase="'SITE_GROUP'">
                    <app-view-site-group></app-view-site-group>
                </ng-container>
                <ng-container *ngSwitchCase="'SITE'">
                    <app-view-site></app-view-site>
                </ng-container>
            </ng-container>
        </div>
    </form>
    <div *ngIf="!loadingHeaderData" class="form-divider"></div>
</div>
