<div ngModelGroup="app-site" id="siteForm" [formGroup]="siteForm">
  <!-- PARENT ORGANIZATION -->
  <mat-form-field appearance="outline" floatLabel="always" data-automation="parentIdField">
    <mat-label>{{ 'customer.parent_id' | translate }}</mat-label>
    <input type="text"
           formControlName="parentId"
           placeholder="{{ 'customer.create.placeholder.parent_id' | translate }}"
           matInput
           required
           [matAutocomplete]="auto">
    <mat-autocomplete
      #auto=matAutocomplete
      data-automation="parent"
      id="parent"
      (optionSelected)='registerSelection($event.option.value)'
      [displayWith]="displayFn"
    >
      <cdk-virtual-scroll-viewport
        itemSize="48"
        [style.height.px]="240"
        minBufferPx="400"
        maxBufferPx="400">
        <mat-option *cdkVirtualFor="let parent of filteredParents" [value]="parent">
          {{ parent.displayLabel }}
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <mat-error class="alert" *ngIf="parentId.hasError('required')">
      <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
    </mat-error>
    <mat-hint *ngIf="loadingParents | async" align="end">{{ 'customer.loading' | translate }}</mat-hint>
    <mat-spinner *ngIf="loadingParents | async" matSuffix diameter="23"></mat-spinner>
  </mat-form-field>

    <!-- NAME  -->
    <form #multiLocaleLabels="ngForm" class="mat-form-field">
        <div class="form-entry" data-automation="name">
            <multi-locale-input
                name="displayLabels"
                [inputLabel]="displayLabelName"
                [locales]="supportedLocales.value"
                [(ngModel)]="multidisplayLabels"
                [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                [localeKey]="multiLocaleConfig.localeKey"
                [defaultLocale]="multiLocaleConfig.defaultLocale"
                [placeholderText]="displayLabelPlaceholder"
                [required]="true"
                id="name"
                [ngModelOptions]="{ standalone: true }"
                #multiDisplaylabels
            >
            </multi-locale-input>
        </div>
    </form>

    <!-- SUPPORTED LOCALES  -->
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'customer.supported_locales' | translate }}</mat-label>
        <mat-select
            formControlName="supportedLocales"
            data-automation="supportedLocales"
            id="supportedLocales"
            placeholder="{{ 'customer.create.placeholder.supported_locales' | translate }}"
            (selectionChange)="handleSelectionChange()"
            multiple
            required
        >
            <mat-option
                *ngFor="let locale of locales"
                [value]="locale"
                [disabled]="limitLocaleSelectList(5, locale) || onlyLocaleSelected(locale)"
            >
                {{ locale.displayLabel }}
            </mat-option>
        </mat-select>
        <mat-error class="alert" *ngIf="supportedLocales.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- DEFAULT LOCALE -->
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'customer.default_locale' | translate }}</mat-label>
        <mat-select
            required
            formControlName="defaultLocale"
            data-automation="defaultLocale"
            placeholder="{{ 'customer.create.placeholder.locale_selector' | translate }}"
        >
            <mat-option
                *ngFor="let supportedLocale of siteForm.controls.supportedLocales.value"
                [value]="supportedLocale.localeName"
            >
                {{ supportedLocale.displayLabel }}
            </mat-option>
        </mat-select>
        <mat-error class="alert" *ngIf="defaultLocale.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- TIMEZONE -->
    <mat-form-field appearance="outline">
        <mat-label>{{ 'customer.timezone' | translate }}</mat-label>
        <mat-select
            required
            formControlName="timezone"
            data-automation="timezone"
            placeholder="{{ 'customer.create.placeholder.timezone_selector' | translate }}"
        >
            <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                {{ timezone.displayLabel }}
            </mat-option>
        </mat-select>
        <mat-error class="alert" *ngIf="timezone.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- SITE USE TYPES -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="siteUseTypeField">
        <mat-label>{{ 'customer.site_use_type' | translate }}</mat-label>
        <mat-select
            formControlName="siteUseTypes"
            data-automation="siteUseType"
            id="siteUseType"
            placeholder="{{ 'customer.create.placeholder.site_use_type' | translate }}"
        >
            <mat-option *ngFor="let siteUseType of siteUseTypesData | async" [value]="siteUseType.id">
                {{ siteUseType.displayLabel }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- SITE FLOOR SPACE -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="siteFloorSpaceField">
        <mat-label>{{ 'customer.site_floor_space' | translate }}</mat-label>
        <input
            type="number"
            id="siteFloorSpace"
            matInput
            placeholder="{{ 'customer.create.placeholder.site_floor_space' | translate }}"
            onkeydown="return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189"
            formControlName="floorSpace"
            data-automation="siteFloorSpace"
            data-url-persist
            (input)="onSearchSiteFloorSpaceChange()"
        />
        <mat-error *ngIf="floorSpace.hasError('pattern')">
            {{ 'customer.validation.invalid.site_floor_space' | translate }}
        </mat-error>
    </mat-form-field>

    <!-- DESCRIPTION -->
    <form #multiLocaleDescriptionLabels="ngForm" class="mat-form-field column">
        <div class="mat-form-field-container" data-automation="description">
            <multi-locale-input
                name="descriptions"
                [(ngModel)]="multiDescriptionsLabels"
                [inputLabel]="descriptionLabel"
                [locales]="supportedLocales.value"
                [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                [localeKey]="multiLocaleConfig.localeKey"
                [defaultLocale]="multiLocaleConfig.defaultLocale"
                [placeholderText]="descriptionPlaceholder"
                [required]="false"
                id="descriptions"
                [ngModelOptions]="{ standalone: true }"
                #multiDescriptionlabels
            >
            </multi-locale-input>
        </div>
    </form>

    <!-- COUNTRY -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="countryField">
        <mat-label>{{ 'customer.country' | translate }}</mat-label>
        <mat-select
            formControlName="country"
            data-automation="country"
            id="country"
            placeholder="{{ 'customer.create.placeholder.country' | translate }}"
            required
            (selectionChange)="onCountryChange($event)"
        >
            <mat-option *ngFor="let country of countries | async" [value]="country.name">
                {{ country.displayLabel }}
            </mat-option>
        </mat-select>
        <mat-error class="alert" *ngIf="country.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- SITE ADDRESS 1 -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="siteAddress1Field">
        <mat-label>{{ 'customer.site_address_1' | translate }}</mat-label>
        <input
            id="siteAddress1"
            matInput
            placeholder="{{ 'customer.create.placeholder.site_address_1' | translate }}"
            formControlName="address1"
            data-automation="siteAddress1"
            data-url-persist
            required
        />
        <mat-error class="alert" *ngIf="address1.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- SITE ADDRESS 2 -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="siteAddress2Field">
        <mat-label>{{ 'customer.site_address_2' | translate }}</mat-label>
        <input
            id="siteAddress2"
            matInput
            placeholder="{{ 'customer.create.placeholder.site_address_2' | translate }}"
            formControlName="address2"
            data-automation="siteAddress2"
            data-url-persist
        />
    </mat-form-field>

    <!-- CITY -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="cityField">
        <mat-label>{{ 'customer.city' | translate }}</mat-label>
        <input
            id="city"
            matInput
            placeholder="{{ 'customer.create.placeholder.city' | translate }}"
            formControlName="city"
            data-automation="city"
            data-url-persist
            required
        />
        <mat-error class="alert" *ngIf="city.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- STATE PROVINCE -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="statesField">
        <mat-label>{{ 'customer.states' | translate }}</mat-label>
        <mat-select
            formControlName="state"
            data-automation="state/province"
            id="state"
            placeholder="{{ 'customer.create.placeholder.states' | translate }}"
            required
        >
            <mat-option *ngFor="let province of provinces | async" [value]="province.name">
                {{ province.displayLabel }}
            </mat-option>
        </mat-select>
        <mat-error class="alert" *ngIf="state.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- ZIP POSTAL CODE  -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="zipPostalCodeField">
        <mat-label>{{ 'customer.zip_postal_code' | translate }}</mat-label>
        <input
            id="zipPostalCode"
            matInput
            placeholder="{{ 'customer.create.placeholder.zipPostalCode' | translate }}"
            formControlName="postalCode"
            data-automation="zip/postalCode"
            data-url-persist
            required
        />
        <mat-error class="alert" *ngIf="postalCode.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- INDUSTRY -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="industryField">
        <mat-label>{{ 'customer.industry' | translate }}</mat-label>
        <mat-select
            formControlName="industry"
            data-automation="industry"
            id="industry"
            placeholder="{{ 'customer.create.placeholder.industry' | translate }}"
            required
            (selectionChange)="onIndustryChange($event)"
        >
            <mat-option *ngFor="let industry of industries | async" [value]="industry.industryName">
                {{ industry.displayLabel }}
            </mat-option>
        </mat-select>
        <mat-error class="alert" *ngIf="industry.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
    </mat-form-field>

    <!-- SUB INDUSTRY -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="subIndustryField">
        <mat-label>{{ 'customer.sub_industry' | translate }}</mat-label>
        <mat-select
            formControlName="subIndustry"
            data-automation="subIndustry"
            id="subIndustry"
            placeholder="{{ 'customer.create.placeholder.sub_industry' | translate }}"
            required
        >
            <mat-option *ngFor="let subIndustry of subIndustries | async" [value]="subIndustry.subIndustryTypeName">
                {{ subIndustry.displayLabel }}
            </mat-option>
        </mat-select>
        <mat-error class="alert" *ngIf="subIndustry.hasError('required')">
            <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
        </mat-error>
        <mat-hint *ngIf="loadingSubIndustries | async" align="end">{{ 'customer.loading' | translate }}</mat-hint>
        <mat-spinner *ngIf="loadingSubIndustries | async" matSuffix diameter="23"></mat-spinner>
    </mat-form-field>

    <!-- CRM ID -->
    <mat-form-field appearance="outline" floatLabel="always" data-automation="ecrmIdField">
        <mat-label>{{ 'customer.ecrm_id' | translate }}</mat-label>
        <input
            id="ecrmId"
            matInput
            placeholder="{{ 'customer.create.placeholder.crm_id' | translate }}"
            formControlName="ecrmId"
            data-automation="crmId"
            data-url-persist
            (input)="onSearchCrmIdChange()"
        />

        <mat-error *ngIf="ecrmId.hasError('pattern')">
            {{ 'customer.validation.invalid.crm_id.pattern' | translate }}
        </mat-error>
        <mat-error *ngIf="ecrmId.hasError('existing')">
            {{ 'customer.validation.invalid.crm_id.existing' | translate }}
        </mat-error>
    </mat-form-field>
</div>
