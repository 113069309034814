<ng-container>
    <div ngModelGroup="app-site-group" id="siteGroupForm" [formGroup]="siteGroupForm">
        <!-- GROUP NAME -->
        <form #multiLocaleLabels="ngForm" class="mat-form-field column">
            <div class="mat-form-field-container" data-automation="siteGroupName">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="supportedLocales.value"
                    [(ngModel)]="multidisplayLabels"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [id]="'site_group_display_labels'"
                    #multiDisplaylabels
                >
                </multi-locale-input>
            </div>
        </form>
        <!-- Parent -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-spinner *ngIf="loadingParents" matPrefix diameter="15"></mat-spinner>
            <mat-label data-automation="parentLabel">{{ 'customer.site_group.parent' | translate }}</mat-label>
            <mat-select
                required
                formControlName="parentId"
                data-automation="parent"
                placeholder="{{ 'customer.create.placeholder.parent.site_group' | translate }}"
                id="parent"
            >
                <mat-option
                    *ngFor="let parent of potentialParents"
                    [value]="parent.id"
                    (click)="handleSelectionChangeParent(parent)"
                >
                    {{ parent.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="parentId.hasError('required')">
                <span data-automation="parentValidationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- SUPPORTED LOCALES -->
        <mat-form-field class="column" appearance="outline" floatLabel="always" data-automation="supportedLocalesField">
            <mat-label data-automation="supportedLocalesLabel">{{
                'customer.organization.supported_locales' | translate
            }}</mat-label>
            <mat-select
                formControlName="supportedLocales"
                data-automation="supportedLocale(s)"
                (selectionChange)="handleSupportedLocalesSelection()"
                multiple
            >
                <mat-option
                    *ngFor="let locale of locales"
                    [value]="locale"
                    [disabled]="limitLocaleSelectList(5, locale) || onlyLocaleSelected(locale)"
                >
                    {{ locale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- DEFAULT LOCALE -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="defaultLocaleLabel"
                >{{ 'customer.organization.default_locale' | translate }}
            </mat-label>
            <mat-select required formControlName="defaultLocale" data-automation="defaultLocale">
                <mat-option
                    *ngFor="let supportedLocale of siteGroupForm.controls.supportedLocales.value"
                    [value]="supportedLocale.localeName"
                >
                    {{ supportedLocale.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error class="alert" *ngIf="defaultLocale.hasError('required')">
                <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- Time Zone -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="timezoneLabel">{{ 'customer.timezone' | translate }}</mat-label>
            <mat-select
                required
                formControlName="timezone"
                data-automation="timezone"
                placeholder="{{ 'customer.create.placeholder.timezone_selector' | translate }}"
            >
                <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                    {{ timezone.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- STATUS -->
        <mat-form-field class="column" appearance="outline">
            <mat-label data-automation="statusLabel">{{ 'customer.organization.status' | translate }}</mat-label>
            <mat-select
                required
                formControlName="status"
                data-automation="status"
                placeholder="{{ 'customer.create.placeholder.status' | translate }}"
            >
                <mat-option *ngFor="let status of statuses" [value]="status.id">
                    {{ status.display_label | translate }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="status.hasError('required')">
                <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- DESCRIPTION -->
        <form #multiLocaleDescriptionLabels="ngForm" class="mat-form-field column">
            <div class="mat-form-field-container" data-automation="description">
                <multi-locale-input
                    name="descriptions"
                    [inputLabel]="descriptionLabel"
                    [(ngModel)]="multiDescriptionDisplayLabels"
                    [locales]="supportedLocales.value"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [required]="false"
                    id="descriptions"
                    [ngModelOptions]="{ standalone: true }"
                    #multiDescriptionlabels
                >
                </multi-locale-input>
            </div>
        </form>
    </div>
</ng-container>

<ng-container>
    <div class="sites-list-container">
        <h2 data-automation="viewTitle" class="points-title">
            {{ 'group.site.title' | translate }}
        </h2>
        <hr class="form-divider" />
        <div *ngIf="loadingSites && isCreateMode" class="btn-spinner">
            <mat-spinner diameter="20"></mat-spinner>
        </div>
        <ng-container *ngIf="!loadingSites && !potentialParents">
            <div class="list-empty list-empty-equipment" data-autmation="no-sites-text">
                {{ 'site.list.table.group.no_site' | translate }}
            </div>
        </ng-container>
        <app-sites-list
            [filter]="true"
            [sort]="true"
            [parentId]="parentId"
            [source]="sitesSourceList"
            [(destination)]="sitesDestinationList"
            (movedItem)="isMovedItem($event)"
            [iconDefinitions]="iconDefinitions"
            height="200px"
            *ngIf="!loadingSites"
        ></app-sites-list>
    </div>
</ng-container>
