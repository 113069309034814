<ng-container *ngIf="dialogState === 'DEFAULT'" [ngSwitch]="data.type">
    <ng-container *ngSwitchCase="'SITE'">
        <h1 mat-dialog-title data-automation="dialogTitle">{{ 'site.delete.title' | translate }}</h1>
        <div mat-dialog-content>
            <div class="separator"></div>
            <p data-automation="dialogText1">
                {{ 'site.delete.text' | translate }}<br />{{ 'delete.cannot_undone.text' | translate }}
            </p>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'ORGANIZATION'">
        <h1 mat-dialog-title data-automation="dialogTitle">{{ 'organization.delete.title' | translate }}</h1>
        <div mat-dialog-content>
            <div class="separator"></div>
            <p data-automation="dialogText1">
                {{ 'organization.delete.text' | translate }}<br />{{ 'delete.cannot_undone.text' | translate }}
            </p>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'SITE_GROUP'">
        <h1 mat-dialog-title data-automation="dialogTitle">{{ 'site_group.delete.title' | translate }}</h1>
        <div mat-dialog-content>
            <div class="separator"></div>
            <p data-automation="dialogText1">
                {{ 'site_group.delete.text' | translate }}<br />{{ 'delete.cannot_undone.text' | translate }}
            </p>
        </div>
    </ng-container>

    <div mat-dialog-actions>
        <button
            class="x-btn-secondary dialog-secondary"
            data-automation="dialogCancel"
            aria-label="Cancel"
            (click)="onNoClick()"
        >
            <mat-icon class="icon" matPrefix svgIcon="cancel"></mat-icon>
            <span> {{ 'customer.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            data-automation="dialogDelete"
            color="accent"
            aria-label="Delete"
            (click)="handleDelete()"
        >
            <mat-icon class="icon" matPrefix svgIcon="delete"></mat-icon>
            <span> {{ 'template.button.delete' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETING'">
    <div mat-dialog-content>
        <div class="dialog-spinner" [ngSwitch]="data.type">
            <div class="spinner-container" data-automation="dialogSpinner">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <span *ngSwitchCase="'SITE'" data-automation="dialogText">{{ 'deleting.site.message' | translate }}</span>
            <span *ngSwitchCase="'SITE_GROUP'" data-automation="dialogText">{{
                'deleting.site_group.message' | translate
            }}</span>
            <span *ngSwitchCase="'ORGANIZATION'" data-automation="dialogText">{{
                'deleting.organization.message' | translate
            }}</span>
        </div>
    </div>
</ng-container>
