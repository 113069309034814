import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import * as convertKeys from 'convert-keys';
import { ConedCredentials } from '../model/coned-credentials';

@Injectable()
export class ConedService {
  readonly customerInfo$ = new BehaviorSubject<any>(null);
  readonly credentials: Promise<ConedCredentials>;

  constructor(private apiService: ApiService) {
    this.credentials = this.getCredentials();
  }

  async getCredentials(): Promise<ConedCredentials> {
    const credentials = await this.apiService.get(`credentials`);
    return convertKeys.toCamel(credentials);
  }

  async authorizeCustomer(authCode: string) {
    try {
      const response = await this.apiService.post('token', { code: authCode });
      this.customerInfo$.next({ error: false, response: response });
    } catch (err) {
      this.customerInfo$.next({ error: true, response: err });
    }
  }
}
