<div class="form-wrapper list-type-header">
    <div class="form-entry">
        <!--- listType --->
        <mat-form-field data-automation="org-context-selector" appearance="outline" floatLabel="always">
            <mat-spinner *ngIf="loadingOrgs" matPrefix diameter="23"></mat-spinner>
            <mat-label class="label">{{ 'organizations.list' | translate }}</mat-label>
            <input
                class="ellipsis"
                data-automation="org-context-input"
                type="text"
                [placeholder]="loadingOrgs ? '' : getSelectedOrgsLabels()"
                matInput
                [formControl]="organizationsListCtrl"
                [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" class="input-entry create-select" (opened)="openChange()">
                <cdk-virtual-scroll-viewport itemSize="25" [ngStyle]="{height: '200px'}">
                    <mat-option *cdkVirtualFor="let org of filteredOptions | async" [value]="org.display_label" id="{{ org.id }}">
                        <div (click)="optionClicked($event, org)">
                            <mat-checkbox
                                (change)="toggleSelection(org)"
                                (click)="$event.stopPropagation()"
                                [disabled]="(org.checked ? false : checkMaxSelections()) || loadingHierarchy"
                                [checked]="org.checked"
                            >
                                <span class="label">{{ org.display_label }}</span>
                            </mat-checkbox>
                        </div>
                    </mat-option>
                </cdk-virtual-scroll-viewport>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
<org-tree></org-tree>
<div class="create-button-container">
    <button
        (click)="routeToCreatePage()"
        data-automation="createButton"
        id="create-button"
        class="icon-btn x-btn-primary"
        color="accent"
    >
        <mat-icon class="add-icon">add</mat-icon>
        <span class="btn-text">{{ 'customer.list.button.create_site' | translate }}</span>
    </button>
</div>
