export interface Customer {
  id: string;
  displayLabel: string;
  timezone: string;
  locales: string;
}

export const statuses = [
  {
    id: 'ACTIVE',
    display_label: 'customer.statuses.active',
  },
  {
    id: 'INACTIVE',
    display_label: 'customer.statuses.inactive',
  },
];
