import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as convertKeys from 'convert-keys';
import { BehaviorSubject, Subject } from 'rxjs';
import { ServiceAccount } from '../model/service-account';
import { GlobalNavService } from 'ngx-global-nav';

const PAGE_SIZE = 1000;

@Injectable()
export class ServiceAccountsService {
  serviceAccounts: any;
  public refeshServiceAccountStatuses$: BehaviorSubject<any> = new BehaviorSubject(false);
  public loadingServiceAccount$: BehaviorSubject<any> = new BehaviorSubject(false);
  public serviceAccounts$: Subject<ServiceAccount[]> = new Subject();
  public updateFinished$: Subject<{
    isUpdated: boolean;
    status: string;
  }> = new Subject();
  public updateFailed$: Subject<boolean> = new Subject();
  constructor(private apiService: ApiService, private globalNavService: GlobalNavService) {}

  /**
   * Get's a service account from the backend and stores it in cache for reuse
   *
   * @returns {Promise<User>}
   */
  async getServiceAccounts() {
    try {
      const firstPageResponse = await this.apiService.get('service-accounts', { page: 1, per_page: PAGE_SIZE }, true);
      const numberOfPages = Math.ceil(firstPageResponse.meta.total / PAGE_SIZE);
      const allPages = [];
      for (let page = 2; page <= numberOfPages; page++) {
        allPages.push(this.apiService.get('service-accounts', { page, per_page: PAGE_SIZE }, true));
      }
      let result = [firstPageResponse];
      const allPagesResponses = await Promise.allSettled(allPages);
      let successes = allPagesResponses.filter(result => {
        return result.status === 'fulfilled';
      });
      const errors = allPagesResponses.filter(result => {
        return result.status === 'rejected';
      });

      if (errors?.length > 0) {
        throw `${JSON.stringify(errors)}`;
      }
      successes = successes.map(success => success['value']);
      result = [...result, ...successes];
      const allDataServiceAccounts = result.reduce((data, response) => {
        return [...data, ...response.data];
      }, []);
      let response: ServiceAccount[] = convertKeys.toCamel(allDataServiceAccounts);
      response = response.filter(serviceAccountResponse => serviceAccountResponse.loaStatus);
      this.serviceAccounts$.next(response);
      return response;
    } catch (err) {
      console.log('Error: Service Account - could not fetch Service Account for this user');
      console.log(JSON.stringify(err));
      this.loadingServiceAccount$.next(false);
    }
  }

  async updateServiceAccounts(serviceAccounts: ServiceAccount, portal_id) {
    //a delay is set to have time to visualize status changes from unauthorized to pending
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.updateServiceAccountsStatus(serviceAccounts, 'UNAUTHORIZED', 'PENDING', portal_id);
    await this.getServiceAccounts();
    this.updateFinished$.next({ isUpdated: true, status: 'PENDING' });
    //a delay is set to have time to visualize status changes from pending to authorized
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.updateServiceAccountsStatus(serviceAccounts, 'PENDING', 'AUTHORIZED', portal_id);
    await this.getServiceAccounts();
    this.updateFinished$.next({ isUpdated: true, status: 'AUTHORIZED' });
    this.globalNavService.getServiceAccounts();
  }

  async updateServiceAccountsStatus(
    serviceAccounts: ServiceAccount,
    statusToFilter: string,
    statusToChange: string,
    portal_id: string,
  ) {
    try {
      //we use this flag to manage the spinner in the ui
      this.updateFinished$.next({ isUpdated: false, status: '' });
      const serviceAccountStatusToChange = {
        loa_status: statusToChange,
        portal_id: portal_id,
      };
      const serviceAccountsResults = await this.apiService.put(
        `service-accounts/${serviceAccounts.id}`,
        serviceAccountStatusToChange,
      );

      if (serviceAccountsResults.status === 'fulfilled') {
        return;
      }

      if (serviceAccountsResults.status === 'rejected') {
        this.updateFailed$.next(true);
        throw `${JSON.stringify(serviceAccountsResults)}`;
      }
    } catch (error) {
      console.log('Error: Service Account - could not update the service account for this user');
      console.log(JSON.stringify(error));
    }
  }
  async updateServiceAccountPortalId(serviceAccount, portal_id) {
    const portalID = {
      portal_id: portal_id,
      loa_status: serviceAccount.loaStatus,
    };
    this.apiService.put(`service-accounts/${serviceAccount.id}`, portalID);
  }
  getServiceAccount() {
    return this.serviceAccounts;
  }

  setServiceAccount(serviceAccounts) {
    this.serviceAccounts = serviceAccounts;
  }
}
