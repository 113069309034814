import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '../shared/model/locale.model';
import { CustomersService } from '../shared/services/customers.service';
import { OrganizationsService, TYPES_DROP_DOWN } from '../shared/services/organizations.service';
import { SitesService } from '../shared/services/sites.service';
import { SiteGroupService } from '../shared/services/site-group.service';
import { SiteGroupParams } from '../shared/model/site-group.model';

const INTEGER_ONLY = /^[0-9]*$/;
const CRM_ID = /^[A-Za-z0-9]{18}$/;
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent {
  ORG_SUCCESS = 'Organization Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  ORG_NOT_CREATED = 'Oops, There was a problem creating your organization';
  SITE_NOT_CREATED = 'Oops, There was a problem creating your site';
  SITE_SUCCESS = 'Site was created successfully';
  SITEGROUP_NOT_CREATED = 'Oops, There was a problem creating your site group';
  SITEGROUP_SUCCESS = 'Site group was created successfully';
  SITEGROUP_RELATIONSHIP_ERROR = 'Oops, There was a problem adding one or more children to the site group';
  REQUIRED = 'required';
  selectedType = 'ORGANIZATION';
  typesDropDown = TYPES_DROP_DOWN;
  isSubmitting = false;

  orgForm: FormGroup = new FormGroup({
    displayLabels: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    parent: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    subIndustry: new FormControl({ value: '', disabled: true }, Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    legalName: new FormControl('', Validators.required),
    status: new FormControl('ACTIVE', Validators.required),
    ecrmId: new FormControl('', Validators.pattern(CRM_ID)),
    descriptions: new FormControl(''),
  });

  siteForm: FormGroup = new FormGroup({
    displayLabels: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    siteUseTypes: new FormControl(''),
    parentId: new FormControl('', Validators.required),
    floorSpace: new FormControl('', Validators.pattern(INTEGER_ONLY)),
    descriptions: new FormControl(''),
    country: new FormControl('', Validators.required),
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl({ value: '', disabled: true }, Validators.required),
    postalCode: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    subIndustry: new FormControl({ value: '', disabled: true }, Validators.required),
    ecrmId: new FormControl('', Validators.pattern(CRM_ID)),
  });

  siteGroupForm: FormGroup = new FormGroup({
    displayLabels: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    parentId: new FormControl({ value: '', disabled: false }, Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    status: new FormControl('ACTIVE', Validators.required),
    descriptions: new FormControl(''),
  });

  readonly APPPREFIX = 'prt';
  readonly mode = 'create';
  selectedOrgId: string;
  isSiteMode = false;

  constructor(
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private sitesService: SitesService,
    private siteGroupService: SiteGroupService,
    private customersService: CustomersService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.selectedType = 'ORGANIZATION';
    this.customersService.selectedCustomerId$.next('');
    this.translateService.get('customer.organization.notification.created_successfully').subscribe((result: string) => {
      this.ORG_SUCCESS = result;
      (this.SITE_SUCCESS = this.translateService.instant('customer.site.notification.created_successfully')),
        (this.BAD_REQUEST = this.translateService.instant('customer.notification.bad_request'));
      this.ORG_NOT_CREATED = this.translateService.instant('customer.organization.notification.not_created');
      this.SITE_NOT_CREATED = this.translateService.instant('customer.site.notification.not_created');
      this.REQUIRED = this.translateService.instant('customer.validation.required');
      this.SITEGROUP_NOT_CREATED = this.translateService.instant('customer.site_group.notification.failure');
      this.SITEGROUP_SUCCESS = this.translateService.instant('customer.site_group.notification.success');
      this.SITEGROUP_RELATIONSHIP_ERROR = this.translateService.instant(
        'customer.site_group.notification.relationships.error',
      );
    });
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX });
    const selectedOrgs = this.organizationService.selectedOrgs$.value;
    return selectedOrgs.length === 0
      ? this.router.navigate(['/'], { queryParams: { type: 'no-selected-org' } })
      : this.router.navigate(['/']);
  }
  ngOnInit() {
    this.selectedOrgId = this.route.snapshot.queryParamMap.get('selectedOrgId');
  }

  isFormValid() {
    if (this.selectedType === 'ORGANIZATION') {
      return !this.orgForm.valid;
    } else if (this.selectedType === 'SITE') {
      return !this.siteForm.valid;
    } else if (this.selectedType === 'SITE_GROUP') {
      return !this.siteGroupForm.valid;
    }
    return null;
  }

  getDisplayTypeSubtitle() {
    let subtitle;
    switch (this.selectedType) {
      case 'ORGANIZATION':
        subtitle = this.translateService.instant('organization.create.subtitle');
        break;
      case 'SITE':
        subtitle = this.translateService.instant('site.create.subtitle');
        break;
      case 'SITE_GROUP':
        subtitle = this.translateService.instant('site_group.create.subtitle');
        break;
      default:
        break;
    }
    return subtitle;
  }

  getDisplayTypeTitle() {
    let title;
    switch (this.selectedType) {
      case 'ORGANIZATION':
        title = this.translateService.instant('organization.create.page_title');
        break;
      case 'SITE':
        title = this.translateService.instant('site.create.page_title');
        break;
      case 'SITE_GROUP':
        title = this.translateService.instant('site_group.create.page_title');
        break;
      default:
        break;
    }
    return title;
  }

  async handleSubmit() {
    let parentId: string = null;
    if (!this.siteForm.valid && !this.orgForm.valid && !this.siteGroupForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.isSubmitting = true;
        let response = null;
        if (this.selectedType === 'ORGANIZATION') {
          response = await this.organizationService.createOrganization({ ...this.orgForm.getRawValue() });
          this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
          this.messageService.setSuccess(this.ORG_SUCCESS);
          parentId = response.id;
          this.organizationService.initPageLoader();
          this.organizationService.setRefreshingList(true);
          this.organizationService.isInit = true;
          this.organizationService.getOrgs();
        } else if (this.selectedType === 'SITE') {
          parentId = this.siteForm.getRawValue().parentId.id;
          const transformedData = this.sitesService.transformFormData(this.siteForm.getRawValue());
          response = await this.sitesService.createSite(transformedData, parentId);
          this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
          this.sitesService.setCurrentParentId(parentId);
          this.messageService.setSuccess(this.SITE_SUCCESS);
        } else if (this.selectedType === 'SITE_GROUP') {
          const formData = this.siteGroupForm.getRawValue();
          const transformedSiteGroupData: SiteGroupParams = this.siteGroupService.transformFormData(formData);
          const childrenSelected = this.siteGroupService.getSitesSelected();
          parentId = formData.parentId;
          try {
            response = await this.siteGroupService.createSiteGroup(transformedSiteGroupData);
            if (childrenSelected && childrenSelected.length > 0) {
              const childrenResponse = await this.siteGroupService.changeParent({
                children: childrenSelected,
                currentParentId: parentId,
                newParentId: response.id,
              });
              if (childrenResponse && !childrenResponse?.failures.length) {
                this.messageService.setSuccess(this.SITEGROUP_SUCCESS);
              } else {
                this.messageService.setError(this.SITEGROUP_RELATIONSHIP_ERROR);
              }
            } else {
              this.messageService.setSuccess(this.SITEGROUP_SUCCESS);
            }
            this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
          } catch (error) {
            this.messageService.setError(this.SITEGROUP_NOT_CREATED);
          }
        }
        this.organizationService.addSelectedOrg(parentId);
        setTimeout(async () => {
          await this.sitesService.refetchOrgs();
          void this.router.navigate([`details/${response.id}/view`], {
            queryParams: {
              type: this.selectedType?.toLowerCase(),
            },
          });
        }, 2000);
      } catch (e) {
        console.log(e);
        if (e) {
          const errorMessage = e.error.message || e.message;
          if (errorMessage === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            if (this.selectedType == 'ORGANIZATION') {
              this.messageService.setError(this.ORG_NOT_CREATED);
            } else if (this.selectedType === 'SITE') {
              this.messageService.setError(this.SITE_NOT_CREATED);
            } else if (this.selectedType === 'SITE_GROUP') {
              this.messageService.setError(this.SITEGROUP_NOT_CREATED);
            }
          }
        }
      }
      this.isSubmitting = false;
    }
  }
  onChangeType(type) {
    switch (type) {
      case 'ORGANIZATION':
        this.isSiteMode = false;
        break;
      case 'SITE':
        this.isSiteMode = true;
        break;
      case 'SITE_GROUP':
        this.isSiteMode = false;
        break;
      default:
        this.isSiteMode = false;
        break;
    }
  }
}
