<app-global-alert></app-global-alert>

<global-nav [multiSelect]="false" [contextList]="orgList" [navUrl]="navUrl"></global-nav>
<mat-sidenav-container class="x-sidebar">
    <mat-sidenav
        #sidenav
        fixedInViewport="true"
        fixedTopGap="60"
        [mode]="isLargeScreen() ? 'side' : 'over'"
        [opened]="sidenavOpen"
        class="sidenav-container"
    >
        <header class="sidenav-header">
            <div class="left-column">
                <h1 class="page-title" data-automation="listTitle" title="{{ 'customer.list.title' | translate }}">
                    {{ 'customer.list.title' | translate }}
                </h1>
            </div>

            <div class="right-column">
                <button
                    mat-icon-button
                    color="accent"
                    aria-label="Menu"
                    class="toggle-sidenav"
                    data-automation="toggle-sidenav"
                    (click)="sidenav.toggle()"
                >
                    <mat-icon>double_arrow</mat-icon>
                </button>
            </div>
        </header>
        <div class="sidenav-component-container" cdkScrollable>
            <app-list></app-list>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <button
            mat-icon-button
            color="accent"
            aria-label="Menu"
            class="toggle-sidenav mobile-btn"
            (click)="sidenav.toggle()"
        >
            <mat-icon>menu</mat-icon>
        </button>
        <div class="main-container">
            <router-outlet></router-outlet>
        </div>

        <global-footer></global-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
