import { IconDefinition, NodeDefinition } from 'ngx-tree-selector';

export interface NodeType {
  type: string;
  label: string;
  typeKey: string;
  pointType: string;
  iconDefinitions: IconDefinition;
  disabledNodeTypes: NodeDefinition;
  resource: string;
}

export const ORG_ICON_DEFINITION: IconDefinition = {
  Admingroup: {
    name: 'people_outline',
    type: '',
    namespace: '',
  },
  AdminGroup: {
    name: 'people_outline',
    type: '',
    namespace: '',
  },
  Organization: {
    name: 'people_outline',
    type: '',
    namespace: '',
  },
  Spacegroup: {
    name: 'domain',
    type: '',
    namespace: '',
  },
  Metergroup: {
    name: 'folder_open',
    type: '',
    namespace: '',
  },
  Site: {
    name: 'location_city',
    type: '',
    namespace: '',
  },
  Meter: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
  Operator: {
    name: 'person_outline',
    type: '',
    namespace: '',
  },
  Program: {
    name: 'list_alt',
    type: '',
    namespace: '',
  },
  Product: {
    name: 'plans',
    type: '',
    namespace: '',
  },
  DRGroup: {
    name: 'people_outline',
    type: '',
    namespace: '',
  },
};
