import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Industry } from '../model/industry';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { SubIndustry } from '../model/sub-industry';

@Injectable()
export class IndustriesService {
  industries$: BehaviorSubject<Industry[]>;
  subIndustries$: Subject<SubIndustry[]>;
  loadingSubIndustries$: BehaviorSubject<boolean>;

  constructor(private apiService: ApiService) {
    this.industries$ = new BehaviorSubject<Industry[]>([]);
    this.subIndustries$ = new BehaviorSubject<SubIndustry[]>([]);
    this.loadingSubIndustries$ = new BehaviorSubject<boolean>(false);
  }

  getLoadingSubIndustries() {
    return this.loadingSubIndustries$.asObservable();
  }

  async setIndustries() {
    const industries = await this.apiService.get('industries');
    this.industries$.next(convertKeys.toCamel(industries));
  }

  async setSubIndustries(industryName) {
    try {
      this.loadingSubIndustries$.next(true);
      let subIndustries = await this.apiService.get('subindustries');
      const industry_id = this.getIndustryId(industryName);
      subIndustries = subIndustries.filter(subIndustry => subIndustry.industry_id == industry_id);
      this.subIndustries$.next(convertKeys.toCamel(subIndustries));
      this.loadingSubIndustries$.next(false);
    } catch (err) {
      console.log(`Could not load sub industries.`, err);
    }
  }

  getIndustryId(industryName) {
    industryName = industryName.toUpperCase();
    const industries: Industry[] = convertKeys.toSnake(this.industries$.value);
    return industries.find(industry => industry.industry_name === industryName).id;
  }
}
