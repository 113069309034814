import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChangeParentParams, SiteGroup, SiteGroupParams } from '../model/site-group.model';
import { AtlasPagesService } from 'atlas-pages-loader';
import { CookieService } from './cookie.service';
import { HttpClient } from '@angular/common/http';
import * as convertKeys from 'convert-keys';

@Injectable()
export class SiteGroupService {
  readonly siteGroups$ = new Subject<SiteGroup[]>();
  readonly fullSiteGroup$ = new Subject<SiteGroup>();
  readonly siteLength$ = new Subject<number>();
  readonly siteGroupNotConverted$ = new Subject<SiteGroup>();
  readonly atlasPagesService: AtlasPagesService;
  readonly parentSite$ = new BehaviorSubject<any>(null);
  readonly selectedGroupId$ = new BehaviorSubject<string>(null);
  group$ = new BehaviorSubject<any>(null);

  currentParentOrg$: BehaviorSubject<any> = new BehaviorSubject(null);
  readonly siteGroupDetails$ = new BehaviorSubject<any>(null);

  contextIds: string[];
  loadingSpaces = false;
  groupListResult: any[];
  sitesSelected: any[];
  initialState: any[];
  removedItems: any[];
  addedItems: any[];

  constructor(private apiService: ApiService, private httpClient: HttpClient, private cookieService: CookieService) {
    this.atlasPagesService = new AtlasPagesService(
      cookieService.getMarketsMock(),
      cookieService.getEnocSession(),
      httpClient,
    );

    this.removedItems = [];
    this.addedItems = [];
  }

  async createRelationships(spaceId: string, relatedId: string) {
    const dto = { space_id: spaceId, related_id: relatedId };
    return await this.apiService.post(`relationships`, dto);
  }

  async deleteRelationships(spaceId: string, relatedId: string) {
    return this.apiService.delete(`relationships/${spaceId}/${relatedId}`);
  }

  async getSpaces(allSpaces) {
    const spaces = [];
    spaces.push(allSpaces);
    if (this.loadingSpaces) {
      return;
    }
    this.loadingSpaces = true;
    let spaceSiteGroups = [];
    let allOrgSiteGroups = [];
    if (spaces) {
      spaces.forEach(space => {
        spaceSiteGroups = [];
        this.findAndReturnSiteGroups(['spacegroup'], 'site', space, spaceSiteGroups);
        allOrgSiteGroups = [...allOrgSiteGroups, ...spaceSiteGroups];
      });
    }
    this.groupListResult = allOrgSiteGroups;
    this.siteGroups$.next((convertKeys.toCamel({ allOrgSiteGroups }) as any).allOrgSiteGroups);
    this.loadingSpaces = false;
  }

  findAndReturnSiteGroups(spaceTypes: string[], coreSpaceType: string, obj: any, memo: any[]) {
    if (obj) {
      if (
        obj.space_type &&
        spaceTypes.includes(obj.space_type.toLowerCase()) &&
        obj.core_space_type.toLowerCase() === coreSpaceType.toLowerCase()
      ) {
        memo.push(obj);
        return;
      }

      if (obj.children) {
        for (const child of obj.children) {
          this.findAndReturnSiteGroups(spaceTypes, coreSpaceType, child, memo);
        }
      }
    }
  }

  getGroupListResult() {
    return this.groupListResult;
  }

  setInitialState(list) {
    if (list) {
      this.initialState = [...list];
    }
  }

  getInitialState() {
    return this.initialState;
  }

  async deleteSiteGroup(id: string) {
    // Check for sitegroups parent
    // Change all children's parents to sitegroup's parent
    // Get all children
    // Get parent

    // Delete sitegroup
    const response = await this.apiService.delete(`site-groups/${id}`);
    return convertKeys.toCamel<any>(response);
  }

  async createSiteGroup(body: any) {
    try {
      const parentOrgId = body.parent_id;
      const createdSiteGroup = await this.apiService.post(`site-groups`, body);
      const siteGroupId = createdSiteGroup.id;
      const relationshipData = {
        space_id: parentOrgId,
        related_id: siteGroupId,
      };
      await this.apiService.post(`relationships`, relationshipData);
      return convertKeys.toCamel<SiteGroup>(createdSiteGroup);
    } catch (err) {
      console.log(`Could not create site-group`, err);
      throw err;
    }
  }

  setSitesSelected(list: any[]) {
    this.sitesSelected = list;
    this.siteLength$.next(this.sitesSelected.length);
  }

  removeItemFromGroup(item) {
    if (!this.removedItems.includes(item)) {
      this.removedItems.push(item);
      this.addedItems = this.addedItems.filter(currentItem => currentItem.id !== item.id);
    }
  }

  addItemToGroup(item) {
    if (!this.addedItems.includes(item)) {
      this.addedItems.push(item);
      this.removedItems = this.removedItems.filter(currentItem => currentItem.id !== item.id);
    }
  }

  getRemovedItems() {
    return this.removedItems;
  }

  getAddedItems() {
    return this.addedItems;
  }

  getSitesSelected() {
    return this.sitesSelected;
  }

  resetItemsList() {
    this.addedItems = [];
    this.removedItems = [];
  }

  setCurrentParentOrgId(id) {
    this.currentParentOrg$.next(id);
  }

  async getSiteGroup(id: string) {
    try {
      const response = await this.apiService.get(`site-groups/${id}`);
      this.selectedGroupId$.next(id);
      if (response) {
        let siteGroup: SiteGroup = convertKeys.toCamel(response);
        siteGroup = {
          ...siteGroup,
          displayLabels: response.display_labels,
          descriptions: response.descriptions,
        };
        this.siteGroupDetails$.next(siteGroup);
      }
    } catch (err) {
      console.log(`Could not get site group`, err);
      throw err;
    }
  }

  transformFormData(formData: any): SiteGroupParams {
    delete formData.supported_locales;

    const transformedData: SiteGroupParams = convertKeys.toSnake<any>(formData);

    transformedData.display_labels = formData.displayLabels;
    transformedData.descriptions = formData.descriptions;

    if (transformedData.descriptions === '') {
      delete transformedData.descriptions;
    }

    return transformedData;
  }

  async changeParent(params: ChangeParentParams) {
    const { children, currentParentId, newParentId } = params;
    const results = {
      successes: [],
      failures: [],
    };
    for (const child of children) {
      try {
        let res = await this.deleteRelationships(currentParentId, child.id);
        if (res && res.code == 200) {
          res = await this.createRelationships(newParentId, child.id);
          if (res && res?.related_id === child.id) {
            console.log(`new relationship for ${child.id} successfully created`);
            results.successes.push({ id: child.id, outcome: 'added', res });
          } else {
            console.log(`failed to create the new relationship for ${child.id}`);
            res = await this.createRelationships(currentParentId, child.id);
            results.failures.push({ id: child.id, outcome: 'reverted', res });
          }
        } else {
          console.log(`failed to remove the existing relationship for ${child.id}`);
          results.failures.push({ id: child.id, outcome: 'aborted', res });
        }
      } catch (e) {
        console.log(`an error occurred changing ${child.id} parent: `, e);
        results.failures.push({ id: child.id, outcome: 'error', e });
      }
    }
    return results;
  }

  async updateSiteGroup(body: SiteGroup) {
    try {
      const siteGroupId = body.id;
      const updatedSiteGroup = await this.apiService.put(`site-groups/${siteGroupId}`, body);
      const response = convertKeys.toCamel<any>(updatedSiteGroup);
      return {
        ...response,
        displayLabels: updatedSiteGroup.display_labels,
      };
    } catch (err) {
      console.log(`Could not update Site Group `, err);
      throw err;
    }
  }
}
