<div class="dual-list">
    <div
        class="listbox"
        data-automation="available-site-groups"
        [ngStyle]="{ order: direction() ? 1 : 2, 'margin-left': direction() ? 0 : '10px' }"
    >
        <h3 class="list-title" data-automation="availableSiteGroups/sites">{{ 'group.site.parent' | translate }}</h3>
        <span class="inform" data-automation="add-group">{{ 'group.site.addGroup' | translate }}</span>

        <div class="container-list">
            <form *ngIf="filter" class="filter">
                <input
                    type="text"
                    name="filterSource"
                    [(ngModel)]="available.picker"
                    (ngModelChange)="onFilter(available)"
                    class="filterInput"
                    placeholder="{{ 'group.site.search' | translate }}"
                />
            </form>
            <div class="record-picker">
                <ng-container *ngIf="!emptySourceList()">
                    <ul [ngStyle]="{ 'max-height': height, 'min-height': height }">
                        <li
                            *ngFor="let item of available.sift | sortByArg: 'displayLabel'"
                            (click)="disabled ? null : moveItem(available, confirmed, item, false, 'right')"
                            class="item_list"
                        >
                            <mat-icon class="tree-icon">{{ getIconByType(item) }}</mat-icon>
                            <div
                                class="child_display_label"
                                [attr.data-automation]="'available_equipment_' + item.displayLabel"
                            >
                                {{ item.displayLabel }}
                            </div>
                            <mat-icon>chevron_right</mat-icon>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="emptySourceList() && parentId.value && emptyDestList()">
                    <div class="icon-empty-container">
                        <mat-icon>warning</mat-icon>
                        <span>{{ 'sitegroup.create.empty_source_list_warning' | translate }}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="emptySourceList() && emptyDestList() && !parentId.value">
                    <div class="icon-empty-container">
                        <mat-icon>warning</mat-icon>
                        <span>{{ 'sitegroup.create.no_parent' | translate }}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <button
            [ngClass]="emptySourceList() ? 'btn-all' : 'btn-all-available'"
            [attr.data-automation]="'addAll'"
            (click)="moveAllItems(available, confirmed, 'right')"
        >
            <span>{{ 'group.site.addAll' | translate }}</span>
        </button>
    </div>

    <div
        class="listbox"
        data-automation="selectedSiteGroups"
        [ngStyle]="{ order: direction() ? 2 : 1, 'margin-left': direction() ? '10px' : 0 }"
    >
        <h3 class="list-title" data-automation="selectedSiteGroups/sites">{{ 'group.site.selected' | translate }}</h3>
        <span class="inform" data-automation="remove-group">{{ 'group.site.removeGroup' | translate }}</span>

        <div class="container-list">
            <div class="record-picker-right">
                <ng-container *ngIf="!emptyDestList()">
                    <ul [ngStyle]="{ 'max-height': height, 'min-height': height }">
                        <li
                            #itmConf
                            *ngFor="let item of confirmed.sift | sortByArg: 'displayLabel'"
                            (click)="disabled ? null : moveItem(confirmed, available, item, false, 'left')"
                            class="item_list"
                        >
                            <mat-icon>chevron_left</mat-icon>
                            <mat-icon class="tree-icon">{{ getIconByType(item) }}</mat-icon>
                            <div
                                class="child_display_label"
                                [attr.data-automation]="'selected_equipment_' + item.displayLabel"
                            >
                                {{ item.displayLabel }}
                            </div>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="emptyDestList()">
                    <div class="icon-empty-selected">
                        <mat-icon>warning</mat-icon>
                        <span>{{ 'sitegroup.create.empty_destination_list_warning' | translate }}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <button
            [ngClass]="emptyDestList() ? 'btn-remove' : 'btn-remove-available'"
            [attr.data-automation]="'removeAll'"
            (click)="moveAllItems(confirmed, available, 'left')"
        >
            <span>{{ 'group.site.removeAll' | translate }}</span>
        </button>
    </div>
</div>
