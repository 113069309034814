import { Component, OnInit } from '@angular/core';
import { SitesService } from '../shared/services/sites.service';
import { IconDefinition } from 'ngx-tree-selector';
import { ORG_ICON_DEFINITION } from '../shared/model/nodeType.model';
import { Subscription } from 'rxjs';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';

@Component({
  selector: 'org-tree',
  templateUrl: './org-tree.component.html',
  styleUrls: ['./org-tree.component.scss'],
})
export class OrgTreeComponent implements OnInit {
  loading = true;
  loadingHierarchy = false;
  expandedKeys = [];
  addKeys = true;
  iconDefinitions: IconDefinition = {
    ...ORG_ICON_DEFINITION,
  };
  orgHierarchy: any[];
  selectedOrgs: any[];

  private subscriptions: Subscription[] = [];

  constructor(
    private sitesService: SitesService,
    private organizationService: OrganizationsService,
    private sidenavService: SidenavService,
    private router: Router,
  ) {
    const orgHierarchySub = this.sitesService.orgHierarchy$.subscribe(orgHierarchy => {
      if (!this.orgHierarchy) {
        this.orgHierarchy = orgHierarchy;
      } else {
        const withChildren = [...orgHierarchy.filter(a => a.children), ...this.orgHierarchy.filter(a => a.children)];
        this.orgHierarchy = [...this.orgHierarchy, ...orgHierarchy];
        this.orgHierarchy = this.orgHierarchy.map(item => {
          return withChildren.find(i => i.id === item.id) ?? item;
        });
      }
    });

    const selectedOrg = this.organizationService.selectedOrgs$.subscribe(selectedOrgs => {
      if (selectedOrgs) {
        this.selectedOrgs = selectedOrgs;
      }
    });

    const currentParentOrgSub = this.sitesService.currentParent$.subscribe(currentParentOrg => {
      if (currentParentOrg && this.addKeys) {
        const indexOfObject = this.expandedKeys.findIndex(id => {
          return id === currentParentOrg;
        });
        if (indexOfObject === -1) {
          this.expandedKeys.push(currentParentOrg);
        }
        this.addKeys = false;
      }
    });

    const loadingHierarchySub = this.sitesService.loadingHierarchy$.subscribe(loading => {
      this.loadingHierarchy = loading;
    });

    this.subscriptions.push(...[orgHierarchySub, selectedOrg, loadingHierarchySub, currentParentOrgSub]);
  }

  ngOnInit() {
    this.loadServices();
    if (this.selectedOrgs && this.selectedOrgs.length > 0 && this.orgHierarchy && this.orgHierarchy.length > 0) {
      this.getfilteredOrgHierarchy();
    }
  }

  getfilteredOrgHierarchy() {
    const userOrderOrgs = [];
    this.selectedOrgs.forEach(orgId => {
      const selected = this.orgHierarchy.find(item => orgId === item.id);
      if (selected !== undefined) {
        userOrderOrgs.push(selected);
      }
    });
    return userOrderOrgs;
  }

  getIconByType(dataItem) {
    if (this.iconDefinitions[dataItem.spaceType] && this.iconDefinitions[dataItem.spaceType].name) {
      return this.iconDefinitions[dataItem.spaceType].name;
    }
  }
  loadServices() {
    this.sitesService.getOrgHierarchies();
  }

  onSelectNode(dataItem) {
    const { id, spaceType } = dataItem;
    if (this.router.url.includes(id)) return;
    this.sidenavService.closeIfMobile();
    const url = `details/${id}/view`;
    this.router.navigate([url], {
      queryParams: {
        type: spaceType === 'Spacegroup' ? 'site_group' : spaceType.toLowerCase()
      }
    });
  }

  isDisplayed(id): boolean {
    if (this.router.url.includes(id)) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
