<div class="context-list">
    <div class="spinner-container" *ngIf="loadingHierarchy">
        <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
    </div>
    <ng-container *ngIf="selectedOrgs.length == 0 && !loadingHierarchy">
        <div class="list-empty" data-automation="empty-list-message">
            {{ 'organizations.empty' | translate }}
        </div>
    </ng-container>
    <ng-container *ngIf="!loadingHierarchy">
        <kendo-treeview
            [nodes]="getfilteredOrgHierarchy()"
            textField="displayLabel"
            childrenField="children"
            kendoTreeViewHierarchyBinding
            kendoTreeViewExpandable
            data-automation="orgTree"
            [expandBy]="'id'"
            [(expandedKeys)]="expandedKeys"
        >
            <ng-template data-type="dataItem.type" kendoTreeViewNodeTemplate let-dataItem>
                <ng-container *ngIf="dataItem">
                    <div
                        class="data-item-container"
                        (click)="onSelectNode(dataItem)"
                        [ngClass]="{ selected: isDisplayed(dataItem.id) }"
                    >
                        <mat-icon class="tree-icon material-icons-outlined">{{ getIconByType(dataItem) }}</mat-icon>
                        <span class="dataItem" data-automation="dataItem">
                            {{ dataItem.displayLabel }}
                        </span>
                    </div>
                </ng-container>
            </ng-template>
        </kendo-treeview>
    </ng-container>
</div>
