import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { CookieService } from 'ngx-shared-services';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs';
import { SitesService } from '../shared/services/sites.service';
import { TranslateService } from '@ngx-translate/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy {
  loading = true;
  listOrgs = [];
  loadingHierarchy = false;
  selectedOrg = [];
  hidden = [];
  loadingOrgs = true;
  organizationsListCtrl = new FormControl();
  filteredOptions: Observable<string[]>;
  sites: any;
  MAX_SELECTION = 10;
  enableSelect = false;
  preferredOrg: string;
  private subscriptions: Subscription[] = [];
  isFirstTime = true;
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  constructor(
    private router: Router,
    private sidenavService: SidenavService,
    private organizationsService: OrganizationsService,
    private cookieService: CookieService,
    private sitesService: SitesService,
    private translateService: TranslateService,
  ) {
    this.preferredOrg = this.cookieService.getCookie('preferred-org-id');
    const organizationSub = this.organizationsService.orgs$.subscribe(async orgs => {
      if (orgs && orgs.length > 0) {
        const refreshingList = this.organizationsService.getRefreshingList();
        if (this.isFirstTime || refreshingList) {
          this.listOrgs = orgs;
          this.isFirstTime = false;
          if (this.selectedOrg.length > 0) {
            this.selectedOrg = [];
            const selectedOrgs = this.organizationsService.selectedOrgs$.value;
            this.refreshSelection(selectedOrgs);
          } else {
            this.checkDefaultOrg();
          }
          for (const org of this.selectedOrg) {
            const index = this.selectedOrg.indexOf(org);
            console.log(index);
            console.log(this.selectedOrg.length - 1);
            await this.sitesService.getOrgHierarchies(true, org, index !== (this.selectedOrg.length - 1));
          }
          if (refreshingList) {
            this.organizationsService.setRefreshingList(false);
          }
        }
        this.loadingOrgs = false;
        this.filteredOptions = this.organizationsListCtrl.valueChanges.pipe(
          startWith(''),
          map((org: string | null) => {
            return org ? this._filter(org) : this.listOrgs;
          }),
        );
        return this.filteredOptions;
      }
    });

    const loadingOrgsSub = this.organizationsService.getLoadingOrgs().subscribe(loading => {
      this.loadingOrgs = loading;
    });

    const selectedOrgsSub = this.organizationsService.selectedOrgs$.subscribe(selectedOrgs => {
      this.refreshSelection(selectedOrgs);
    });

    const loadingSiteSub = this.sitesService.loadingHierarchy$.subscribe(loading => {
      this.loadingHierarchy = loading;
    });

    this.subscriptions.push(
      ...[
        organizationSub,
        loadingSiteSub,
        selectedOrgsSub,
        loadingOrgsSub,
      ],
    );
  }

  async ngOnInit() {
    await this.organizationsService.getOrgs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.listOrgs.filter(option => option.display_label.toLowerCase().includes(filterValue));
  }

  checkMaxSelections() {
    return this.selectedOrg.length >= this.MAX_SELECTION;
  }

  refreshSelection(selectedOrgs) {
    if (selectedOrgs && selectedOrgs.length > 0) {
      const unselectedOrgsIds = selectedOrgs.filter(orgId => !this.selectedOrg.map(org => org.id).includes(orgId));
      if (unselectedOrgsIds.length > 0) {
        const unselectedOrgs = this.listOrgs.filter(org => unselectedOrgsIds.includes(org.id));
        this.selectedOrg.push(...unselectedOrgs);
        if (!this.listOrgs || this.listOrgs.length === 0) {
          this.selectedOrg = [...this.selectedOrg, selectedOrgs];
        }
        this.listOrgs = this.listOrgs.map(org => {
          if (unselectedOrgsIds.includes(org.id)) {
            org.checked = true;
            org.selected = true;
          }
          return org;
        });
      }
    }
  }

  getSelectedOrgsLabels() {
    if (this.selectedOrg.length > 0) {
      return this.selectedOrg.map(org => org.displayLabel).sort().join(', ');
    } else {
      return this.translateService.instant('organizations.placeholder');
    }
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }

  async toggleSelection(item) {
    item.selected = item.selected || false;
    item.selected = !item.selected;
    item.checked = item.selected;
    if (item.selected && this.selectedOrg.length >= this.MAX_SELECTION) return;
    if (item.selected) {
      const i = this.selectedOrg.findIndex(value => value.id === item.id);
      if (i === -1) {
        const existingItem = this.hidden.find(i => i.id === item.id);
        if (existingItem) {
          this.selectedOrg.push(existingItem);
        } else {
          this.selectedOrg.push(item);
          await this.sitesService.getOrgHierarchies(true, item);
        }
      }
    } else {
      const i = this.selectedOrg.findIndex(value => value.id === item.id);
      const spliced = this.selectedOrg.splice(i, 1);
      if (!this.hidden.find(i => i.id === spliced[0].id)) {
        this.hidden.push(spliced[0]);
      }
    }
    if (this.enableSelect) this.enableSelection();
    this.organizationsService.changeSelection(this.selectedOrg.sort((a: any, b: any) => a.display_label?.localeCompare(b.display_label)).map(org => org.id));
  }

  optionClicked(event: Event, item) {
    event.stopPropagation();
    void this.toggleSelection(item);
  }

  enableSelection() {
    this.listOrgs.forEach(org => {
      org.disabled = false;
    });
    this.enableSelect = false;
  }

  checkDefaultOrg() {
    const index = this.listOrgs.findIndex(org => org.id === this.preferredOrg);
    if (this.preferredOrg && index !== -1) {
      this.listOrgs[index].checked = true;
      this.listOrgs[index].selected = true;
      this.selectedOrg.push(this.listOrgs[index]);
    } else {
      const index = this.selectedOrg.findIndex(org => org.id === this.listOrgs[0].id);
      this.updateCheckList();
      if (index === -1) {
        this.selectedOrg.push(this.listOrgs[0]);
      }
    }
    this.organizationsService.changeSelection(this.selectedOrg.map(org => org.id));
  }

  updateCheckList() {
    if (this.selectedOrg.length > 0) {
      for (const orgSelected of this.selectedOrg) {
        const index = this.listOrgs.findIndex(org => org.id === orgSelected.id);
        this.listOrgs[index].checked = true;
        this.listOrgs[index].selected = true;
      }
    } else {
      this.listOrgs[0].checked = true;
      this.listOrgs[0].selected = true;
    }
  }

  openChange() {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
  }
}
