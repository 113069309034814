<div class="main-container">
    <ng-container [ngSwitch]="currentPage">
        <ng-container *ngSwitchCase="'authorizations'">
            <!-- HEADER STARTS -->
            <header class="sticky-header">
                <div class="left-column">
                    <div class="icon-header">
                        <div class="icon-container">
                            <mat-icon *ngIf="!loadingCustomerAdmin">dashboard</mat-icon>
                            <mat-spinner
                                *ngIf="loadingCustomerAdmin"
                                matSuffix
                                diameter="30"
                                color="warn"
                            ></mat-spinner>
                        </div>
                        <h1 id="view-title" data-automation="viewTitle">
                            {{ 'customer.utility_name' | translate }}: <span>{{ utilityName }}</span>
                        </h1>
                    </div>
                </div>

                <div class="right-column">
                    <button
                        class="icon-btn x-btn-primary"
                        data-automation="authButton"
                        [hidden]="serviceAccounts.length === 0"
                        [disabled]="authButton"
                    >
                        <mat-icon>verified_user</mat-icon>
                        <a class="link" (click)="openAuth()">{{ 'customer.auth_button' | translate }}</a>
                    </button>
                </div>
            </header>
            <!-- HEADER ENDS -->
        </ng-container>
        <ng-container *ngSwitchCase="'thank-you-page'">
            <app-thank-you></app-thank-you>
        </ng-container>
        <!-- SERVICE ACCOUNTS STARTS -->
        <div class="spinner-container" *ngIf="loading">
            <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
        </div>
        <div class="service-accounts-container" *ngIf="!loading">
            <h4 class="form-header service-accounts-header">{{ 'customer.service_accounts' | translate }}</h4>
            <div
                class="service-list-body"
                *ngFor="let serviceAccount of serviceAccounts"
                data-automation="serviceAccountsRow"
            >
                <div class="left-column">
                    <mat-icon
                        [ngClass]="
                            serviceAccount.loaStatus == 'UNAUTHORIZED'
                                ? 'cancel_mark'
                                : serviceAccount.loaStatus == 'AUTHORIZED'
                                ? 'authorized_mark'
                                : 'pending_mark'
                        "
                        >{{
                            serviceAccount.loaStatus == 'UNAUTHORIZED'
                                ? 'cancel'
                                : serviceAccount.loaStatus == 'AUTHORIZED'
                                ? 'check_circle'
                                : 'pending'
                        }}</mat-icon
                    >
                    <p data-automation="serviceAccountsName">
                        {{ serviceAccount.displayLabel }}
                    </p>
                </div>
                <div class="right-column" data-automation="serviceAccountsStatus">
                    <span
                        [attr.data-automation]="serviceAccount.loaStatus"
                        [ngClass]="
                            serviceAccount.loaStatus == 'UNAUTHORIZED'
                                ? 'unauthorized'
                                : serviceAccount.loaStatus == 'AUTHORIZED'
                                ? 'authorized'
                                : 'pending'
                        "
                        >{{ serviceAccount.loaStatus }}</span
                    >
                </div>
            </div>
            <div class="service-messages-body">
                <div class="center-column" *ngIf="noServiceAccountsAvailable">
                    <div><mat-icon svgIcon="noData"></mat-icon></div>
                    <h6>{{ 'customer.no_results' | translate }}</h6>
                    <div class="empty-filler">{{ 'customer.no_service_account' | translate }}</div>
                </div>
                <div class="center-column" *ngIf="isRetrievingServiceAccounts">
                    <div class="spinner-container">
                        <mat-spinner matSuffix diameter="30" color="warn"></mat-spinner>
                    </div>
                    <h6 data-automation="loading-service-accounts">
                        {{ 'customer.service_accounts_loading' | translate }}
                    </h6>
                    <div class="empty-filler">{{ 'customer.service_accounts_retrieve' | translate }}</div>
                </div>
            </div>
        </div>
        <!-- SERVICE ACCOUNTS ENDS -->
    </ng-container>

    <!-- TERMS AND CONDITIONS STARTS -->
    <div class="center-content">
        <a data-automation="termsLink" (click)="openTerms()">{{ 'customer.terms' | translate }} </a>
    </div>
    <!-- TERMS AND CONDITIONS ENDS -->
</div>
