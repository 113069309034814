import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Customer } from '../model/customer';
@Injectable()
export class CustomersService {
  conflictError = new Subject<any[]>();
  readonly customers$ = new BehaviorSubject<Customer[]>(null);
  readonly customer$ = new BehaviorSubject<Customer>(null);
  readonly selectedCustomerId$ = new BehaviorSubject<string>(null);
  readonly selectedCustomer$ = new BehaviorSubject<Customer>(null);

  constructor(private apiService: ApiService) {}

  async getCustomers() {
    try {
      const customers = await this.apiService.get(`customers`);
      this.customers$.next(convertKeys.toCamel(customers));
    } catch (err) {
      console.log('Could not load customers.', err);
    }
  }

  async getCustomer(id: string) {
    let customer: any = {};

    try {
      const response = await this.apiService.get(`customers/${id}`);

      if (response.status === 409) {
        const { data } = response;
        this.conflictError.next(null);
        customer = data.customer;
      } else {
        customer = response;
      }

      delete customer.alternate_ids;

      customer = convertKeys.toCamel(customer);
      this.customer$.next(customer);
      this.selectedCustomerId$.next(id);
      return customer;
    } catch (err) {
      console.log('Could not load customer.', err);
      throw err;
    }
  }

  selectCustomer(customerId: string) {
    if (this.customers$.value && this.customers$.value.length > 0) {
      const customer = this.customers$.value.find(customer => {
        return customer.id === customerId;
      });
      this.selectedCustomer$.next(customer);
    }
  }

  async refetchCustomers() {
    if (this.selectedCustomer$.value) {
      await this.getCustomer(this.selectedCustomer$.value.id);
    }
  }

  async createCustomer(customer: Customer) {
    try {
      const dto = convertKeys.toSnake<any>(customer);
      const createdCustomer = await this.apiService.post('customers', dto);
      return convertKeys.toCamel<Customer>(createdCustomer);
    } catch (err) {
      console.log(`Could not Create Customer`, err);
      throw err;
    }
  }

  async deleteCustomer(customerId: string) {
    const deletedCustomer = await this.apiService.delete(`customers/${customerId}`);
    return convertKeys.toCamel<any>(deletedCustomer);
  }

  async updateCustomer(customer: Customer) {
    try {
      const id = customer.id;
      const dto = convertKeys.toSnake<any>(customer);
      const updatedCustomer = await this.apiService.put(`customers/${id}`, dto);
      return convertKeys.toCamel<any>(updatedCustomer);
    } catch (err) {
      console.log(`Could not Update Customer`, err);
      throw err;
    }
  }
}
