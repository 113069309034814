/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';

import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { TreeSelectorModule } from 'ngx-tree-selector';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global_alert/global-alert.module';
import { I18nService } from './shared/services/i18n.service';
import { MultiLocaleModule } from 'multi-locale-input';
import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { SiteFormComponent } from './details/site/site-form.component';
import { DeleteDialogComponent } from './dialogs/delete/delete-dialog.component';
import { ViewComponent } from './view/view.component';
import { DefaultComponent } from './default/default.component';
import { OrgTreeComponent } from './org-tree/org-tree.component';
import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { TimezonesService } from './shared/services/timezones.service';
import { SidenavService } from './shared/services/sidenav.service';
import { NavService } from './shared/services/global-nav.service';
import { CustomersService } from './shared/services/customers.service';
import { OrganizationsService } from './shared/services/organizations.service';
import { ConedService } from './shared/services/coned.service';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OrganizationFormComponent } from './details/organization/organization.component';
import { SiteGroupFormComponent } from './details/site_group/site-group-form.component';
import { FormValidatorService } from './shared/services/form-validator.service';
import { EcrmService } from './shared/services/ecrm.service';
import { ViewSiteComponent } from './view-site/view-site.component';
import { SiteListComponent } from './details/site_list/site-list.component';

/* * * MATERIAL * * */
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

import localeIt from '@angular/common/locales/it';
import localeZh from '@angular/common/locales/zh';
import localePl from '@angular/common/locales/pl';
import localeJa from '@angular/common/locales/ja';
import localeNb from '@angular/common/locales/nb';
import { AuthorizationComponent } from './authorization/authorization.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ServiceAccountsService } from './shared/services/service-accounts.service';
import { SitesService } from './shared/services/sites.service';
import { IndustriesService } from './shared/services/industries.service';
import { SiteUseTypesService } from './shared/services/site-use-types.service';
import { CountriesService } from './shared/services/countries.service';
import { SiteGroupService } from './shared/services/site-group.service';
import { ViewSiteGroupComponent } from './view-site-group/view-site-group.component';
import { SortByArgPipe } from './shared/pipes/sort-by-arg.pipe';

registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeNb, 'nb-NO');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    ListComponent,
    CreateComponent,
    EditComponent,
    SiteFormComponent,
    DeleteDialogComponent,
    ViewComponent,
    ViewSiteGroupComponent,
    ViewSiteComponent,
    AuthorizationComponent,
    ThankYouComponent,
    OrgTreeComponent,
    OrganizationFormComponent,
    SiteGroupFormComponent,
    SiteListComponent,
    SortByArgPipe,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    GlobalFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    AppRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    NgxDeeplinkerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    MatCheckboxModule,
    TreeSelectorModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    ReactiveFormsModule,
    MatListModule,
    MatAutocompleteModule,
    TreeViewModule,
    ScrollingModule,
    MultiLocaleModule,
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    CustomersService,
    GlobalAlertService,
    I18nService,
    TimezonesService,
    LocalesService,
    DeeplinksService,
    MatDatepickerModule,
    SidenavService,
    NavService,
    OrganizationsService,
    ConedService,
    ServiceAccountsService,
    SitesService,
    SiteGroupService,
    IndustriesService,
    SiteUseTypesService,
    CountriesService,
    FormValidatorService,
    EcrmService,
    SiteGroupService,

    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
