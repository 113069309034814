<div class="app-site-view content" *ngIf="siteData">
    <form #view="ngForm" class="site-form">
        <div class="form-group">
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site_group.parent.display_label' | translate }}:</mat-label>
                <div class="field-value" data-automation="parent">{{ getParentDisplayLabel() }}</div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site_group.timezone' | translate }}:</mat-label>
                <div class="field-value" data-automation="timezone">{{ getTimezoneDisplayLabel() }}</div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site_group.default_locale' | translate }}:</mat-label>
                <div class="field-value" data-automation="defaultLocale">
                    {{ getDefaultLocale() }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site_group.status' | translate }}:</mat-label>
                <div class="field-value" data-automation="status">
                    {{ getStatus() }}
                </div>
            </div>
            <div class="view-field-container small nowrap">
                <mat-label class="field-label">{{ 'customer.description' | translate }}:</mat-label>
                <div class="field-value" data-automation="description">
                    {{ getDescription() }}
                </div>
            </div>
        </div>
    </form>
    <h2 data-automation="viewTitle" class="list-title">
        {{ 'site_group.children' | translate }}
    </h2>
    <hr class="form-divider" />
    <div class="view-field-container small">
        <mat-spinner
            *ngIf="loadingChildren || loadingFromChildrenList"
            matSuffix
            diameter="30"
            color="warn"
            class="list-subjects"
        ></mat-spinner>
        <div *ngFor="let child of sitesSourceList" (click)="onSelect(child)" class="list-subjects">
            <div>
                <mat-icon class="tree-icon">{{ getIconByType(child) }}</mat-icon>
                <mat-label class="list-name" data-automation="listName">{{ child.displayLabel }}</mat-label>
            </div>
        </div>
        <mat-label
            *ngIf="!loadingChildren && !sitesSourceList?.length && !loadingFromChildrenList"
            class="list-subjects"
            >{{ 'site_group.no.children' | translate }}</mat-label
        >
    </div>
</div>
