import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Country } from '../model/country';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CountriesService {
  countries$: BehaviorSubject<Country[]>;
  provinces$: BehaviorSubject<any[]>;
  selectedCountry$: BehaviorSubject<string>;

  constructor(private apiService: ApiService) {
    this.countries$ = new BehaviorSubject<Country[]>([]);
    this.provinces$ = new BehaviorSubject<any[]>([]);
    this.selectedCountry$ = new BehaviorSubject<string>('');
  }

  getCountries() {
    return this.countries$.asObservable();
  }

  async setStates(countryName) {
     if (this.countries$.value && this.countries$.value.length > 0) {
      const res = this.countries$.value.find(item => item.name === countryName, countryName);
      if (res){
        this.provinces$.next(convertKeys.toCamel(res.provinces));
      } 
    }
  }

  async setCountries() {
    const countries = await this.apiService.get('countries');
    this.countries$.next(convertKeys.toCamel(countries));
  }

  setSelectedCountry(country) {
    this.selectedCountry$.next(country);
  }
}
