import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from '../shared/services/organizations.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
  loading = true;
  noTemplates = false;
  templates: any;
  firstOrg: string;
  noOrgs = false;
  subscriptions: any[];
  queryParams: any;

  constructor(
    private router: Router,
    private organizationService: OrganizationsService,
    private route: ActivatedRoute,
  ) {}

  isEdit() {
    return this.router.url.includes('edit');
  }
  isCreate() {
    return this.router.url.includes('create');
  }
  isView() {
    return this.router.url.includes('view');
  }

  navigateToFirstOrganization() {
    if (this.firstOrg && this.queryParams.type !== 'Site') {
      this.router.navigate([`details/${this.firstOrg}/view`], { queryParams: { type: 'organization' } });
    }
  }

  ngOnInit() {
    const querySubscription = this.route.queryParams.subscribe(params => (this.queryParams = params));

    const selectedOrgsSubscription = this.organizationService.selectedOrgs$.subscribe((orgs: string[]) => {
      if (orgs.length > 0) {
        this.noOrgs = false;
        this.loading = true;
        if (this.router.isActive('/', false)) {
          this.firstOrg = orgs[0];
          return this.navigateToFirstOrganization();
        }
      } else {
        this.noOrgs = true;
        if (!this.router.isActive('/', false)) {
          return this.router.navigate(['/']);
        } else {
          if (this.queryParams?.type === 'no-selected-org') {
            this.loading = false;
          }
        }
      }
    });

    this.subscriptions = [selectedOrgsSubscription, querySubscription];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
