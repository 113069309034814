import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GlobalNavService, Space, Context } from 'ngx-global-nav';
import { AnalyticsService, CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { CustomersService } from './shared/services/customers.service';
import { Router } from '@angular/router';
import { NavService } from './shared/services/global-nav.service';
import {Customer} from './shared/model/customer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  programs: any[];
  sidenavOpen = true;
  selectedcustomerId: string;
  isInit = true;
  orgList: Context[] = [];
  customers: Customer[] = [];
  navUrl = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private apiEndpointsService: ApiEndpointsService,
    private globalNavService: GlobalNavService,
    private analytics: AnalyticsService,
    private authService: AuthService,
    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private CustomersService: CustomersService,
    private navService: NavService,
  ) {
    this.CustomersService.customers$.subscribe(customers => {
      if (customers && customers.length > 0) {
        this.customers = customers;
        this.CustomersService.selectCustomer(this.customers[0].id);
      }
    });
    this.CustomersService.selectedCustomer$.subscribe(customer => {
      if (customer) {
        this.selectedcustomerId = customer.id;
      }
    });
    // this.CustomersService.getCustomers();
    this.getNavUrl();
  }

    async getNavUrl() {
      this.navUrl = await this.navService.getNavUrl();
    }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe((result) => {
        document.title = result;
      })
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }

  selectcustomer(e) {
    this.CustomersService.selectCustomer(e);
    if (!this.router.isActive('/', true)) {
      this.router.navigate([`/`], {});
    }
  }
}
