import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalesService } from 'src/app/shared/services/locales.service';
import { TimezonesService } from 'src/app/shared/services/timezones.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { Locale, LocaleBaseConfig } from 'src/app/shared/model/locale.model';
import { Timezone } from 'src/app/shared/model/timezone.model';
import { statuses as CustomerStatus } from 'src/app/shared/model/customer';
import { OrganizationsService } from 'src/app/shared/services/organizations.service';
import { IndustriesService } from 'src/app/shared/services/industries.service';
import { Subscription } from 'rxjs';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { ActivatedRoute } from '@angular/router';
import { EcrmService } from "../../shared/services/ecrm.service";

@Component({
  selector: 'app-org',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class OrganizationFormComponent implements OnInit {
  readonly CREATE = 'create';
  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  locales: Locale[];
  timezones: Timezone[];
  statuses = CustomerStatus;
  adminGroups: any[];
  industries: any[];
  subIndustries: any[];
  descriptionLabel = '';
  descriptionPlaceholder = '';
  multiDescriptionDisplayLabels: any;
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [],
    locales: [],
    defaultLocale: null,
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  namePlaceholder = '';
  userLocale = 'en_US';
  multidisplayLabels: any;
  nameLabel  = '';
  orgParentId: string;
  orgSites: any[];
  tempIndustry: string;
  @ViewChild('multiLocaleLabels') multiLocaleDisplayLabels: any;
  @ViewChild('multiLocaleDescriptionLabels') multiLocaleDescriptionDisplayLabels: any;

  private org: any;
  private subscriptions: Subscription[] = [];
  private defaultMode: string;

  @Input() orgForm: FormGroup;
  @Input()
  set organization(organization: any) {
    this.org = organization;
    this.fillSubIndustries();
    this.getParentId();
  }
  get organization() {
    return this.org;
  }

  @Input()
  set mode(mode: string) {
    this.defaultMode = mode;
  }

  get mode() {
    return this.defaultMode;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }
  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get displayLabels() {
    return this.orgForm && this.orgForm.get('displayLabels');
  }
  get supportedLocales() {
    return this.orgForm && this.orgForm.get('supportedLocales');
  }

  get industry() {
    return this.orgForm && this.orgForm.get('industry');
  }

  get loadingSubIndustries() {
    return this.industriesService.getLoadingSubIndustries();
  }

  get subIndustry() {
    return this.orgForm && this.orgForm.get('subIndustry');
  }

  get status() {
    return this.orgForm && this.orgForm.get('status');
  }

  get defaultLocale() {
    return this.orgForm && this.orgForm.get('defaultLocale');
  }

  get descriptions() {
    return this.orgForm && this.orgForm.get('descriptions');
  }

  get legalName() {
    return this.orgForm && this.orgForm.get('legalName');
  }

  get timezone() {
    return this.orgForm && this.orgForm.get('timezone');
  }

  get parent() {
    return this.orgForm && this.orgForm.get('parent');
  }

  get ecrmId() {
    return this.orgForm && this.orgForm.get('ecrmId');
  }

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
    private organizationsService: OrganizationsService,
    private industriesService: IndustriesService,
    private formValidatorService: FormValidatorService,
    private route: ActivatedRoute,
    private ecrmService: EcrmService
  ) {
    this.timezones = timezonesService.timezones;
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.nameLabel = this.translateService.instant('customer.organization.name');
    this.namePlaceholder = this.translateService.instant('customer.create.placeholder.organization.name');
    this.descriptionLabel = this.translateService.instant('customer.organization.description');
    this.descriptionPlaceholder = this.translateService.instant('customer.organization.create.placeholder.description');
  }
  ngOnInit(): void {
    this.loadDynamicLists();

    const routeSub = this.route.params.subscribe((params: { id: string }) => {
      const orgId: string = params.id;
      let type;
      if (this.route.queryParams['value']['type']) {
        type = this.route.queryParams['value']['type'].toUpperCase();
      }
      if (orgId && type === 'ORGANIZATION') {
        try {
          this.organizationsService.getOrganizationById(orgId);
          this.organizationsService.addSelectedOrg(orgId);
        } catch (e) {
          console.error(`Couldn't load organization with id="${orgId}"`);
          console.error(e);
        }
      }
    });

    const industriesServiceSub = this.industriesService.industries$.subscribe(industries => {
      if (industries && industries.length !== 0) {
        this.industries = industries;
        if (this.orgForm) {
          this.orgForm
            .get('industry')
            .setValidators([Validators.required, this.validIndustryValidator(this.industries, 'industryName')]);
        }
      }
    });
    const localeServiceSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      if (this.isCreateMode) {
        this.multiLocaleConfig.defaultLocale = locale;
        this.defaultLocale.patchValue(locale.localeName);
        this.supportedLocales.patchValue([locale]);
      } else if (this.isEditMode) {
        this.multiLocaleConfig.defaultLocale = locale;
        this.buildSupportedLocales();
      }
    });
    const organizationServiceSub = this.organizationsService.adminGroups$.subscribe(groups => {
      this.adminGroups = groups;
    });

    const orgDetailsSub = this.organizationsService.orgDetails$.subscribe(orgDetails => {
      this.organization = orgDetails;
    });

    const orgsSub = this.organizationsService.org$.subscribe(async org => {
      if (org) {
        this.org = org;
        if (this.isEditMode) {
          this.subIndustry.patchValue(this.org.subindustry);
          if (this.org.industry) {
            this.industriesService.setSubIndustries(this.org.industry);
          }
          this.multidisplayLabels = this.org.displayLabels;
          this.multiDescriptionDisplayLabels = this.org.descriptions || {};
          if (this.org.alternateIds) {
            this.ecrmId.patchValue(this.org.alternateIds.ecrmId);
          } else {
            this.ecrmId.patchValue('');
          }
          this.buildSupportedLocales();
        }
      }
    });

    const subIndustriesServiceSub = this.industriesService.subIndustries$.subscribe(subIndustries => {
      this.subIndustries = subIndustries;
      if (this.isEditMode) {
        this.orgForm
          .get('subIndustry')
          .setValidators([Validators.required, this.validIndustryValidator(this.subIndustries, 'subIndustryTypeName')]);
        this.formValidatorService.triggerControlValidation(this.subIndustry);
      }
    });

    const timezoneServiceSub = this.timezonesService.timezones$.subscribe(timezones => {
      this.timezones = timezones;
    });
    this.subscriptions = [
      routeSub,
      localeServiceSub,
      organizationServiceSub,
      subIndustriesServiceSub,
      timezoneServiceSub,
      industriesServiceSub,
      orgsSub,
      orgDetailsSub,
    ];
  }
  loadDynamicLists() {
    this.organizationsService.getAdminGroups();
    this.industriesService.setIndustries();
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }

  triggerValidation() {
    this.displayLabels.markAsTouched();
    this.displayLabels.updateValueAndValidity();
  }

  handleSupportedLocalesSelection() {
    this.triggerValidation();
    const selectedLocales = this.supportedLocales.value.map(locale => locale.localeName);
    if (!selectedLocales.includes(this.defaultLocale.value)) {
      this.defaultLocale.setValue(selectedLocales[0]);
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  onIndustryChange($event) {
    this.industriesService.loadingSubIndustries$.next(true);
    this.subIndustry.disable();
    this.handleIndustriesSelection($event.value, true);
    this.formValidatorService.triggerControlValidation(this.subIndustry);
  }

  onlyLocaleSelected(locale) {
    return this.supportedLocales.value.length == 1 && this.supportedLocales.value[0] == locale;
  }

  handleIndustriesSelection(industryName, fromEvent: boolean) {
    const loadingSubIndustriesSub = this.industriesService.loadingSubIndustries$.subscribe(loadingSubIndustries => {
      if (!loadingSubIndustries && this.subIndustry) {
        this.subIndustry.enable();
      }
    });
    if (fromEvent) {
      this.subIndustry.setValue('');
    }
    this.industriesService.setSubIndustries(industryName);
    this.subscriptions.push(loadingSubIndustriesSub);
  }

  ngAfterViewInit() {
    if (this.multiLocaleDisplayLabels && this.multiLocaleDescriptionDisplayLabels) {
      const multilocaleDisplayLabelSub = this.multiLocaleDisplayLabels.valueChanges.subscribe(multilocales => {
        let newValue = {};
        if (multilocales.displayLabels) {
          newValue = multilocales.displayLabels;
        } else {
          newValue = this.transformMultiLocaleValue(multilocales.localeInputsForm_organization_display_labels);
        }
        const status = this.multiLocaleDisplayLabels.form.status;
        setTimeout(() => {
          this.updateDisplayLabels(status, newValue);
        }, 10);
      });

      const multilocalesDescriptionSub = this.multiLocaleDescriptionDisplayLabels.valueChanges.subscribe(
        multilocales => {
          const newValue = this.transformMultiLocaleDescriptionValue(
            multilocales.localeInputsForm_organization_description_display_labels,
          );
          const status = this.multiLocaleDescriptionDisplayLabels.form.status;
          this.updateDescriptionDisplayLabels(status, newValue);
        },
      );

      const ecrmCheckSub = this.orgForm.controls['ecrmId'].valueChanges.subscribe({
        next: async (ecrmIdValue: string) => {
          if (this.orgForm.controls['ecrmId'].valid && ecrmIdValue.length > 0) {
            await this.ecrmService.checkTranslation(ecrmIdValue, this.org?.id);
          }
        }
      });

      const flexSub = this.ecrmService.checkFlexTranslation$.subscribe({
        next: async (check: boolean) => {
          if (!check) {
            this.orgForm.get('ecrmId').setErrors({'existing': true});
          }
        }
      });

      this.subscriptions = [multilocaleDisplayLabelSub, multilocalesDescriptionSub, ecrmCheckSub, flexSub];
    }
  }
  transformMultiLocaleValue(multilocales) {
    const displayLabels = {};
    if (multilocales) {
      const locales = Object.keys(multilocales);
      if (locales) {
        for (const locale of locales) {
          const key = locale
            .replace('organization_display_labels_', '')
            .split('_')
            .map((item, i) => (i == 1 ? item.toUpperCase() : item))
            .join('_');
          displayLabels[key] = multilocales[locale];
        }
      }
    }
    return displayLabels;
  }
  updateDisplayLabels(status, newValue) {
    this.displayLabels.setValue({ ...newValue });
    this.displayLabels.markAsTouched();
    if (status == 'INVALID') {
      this.displayLabels.setErrors({ required: true });
    } else {
      this.displayLabels.setErrors(null);
    }
  }

  updateDescriptionDisplayLabels(status, newValue) {
    this.descriptions.setValue({ ...newValue });
    this.descriptions.markAsTouched();
    if (status == 'INVALID') {
      this.descriptions.setErrors({ required: true });
    } else {
      this.descriptions.setErrors(null);
    }
  }
  transformMultiLocaleDescriptionValue(multilocales) {
    const descriptions = {};
    if (multilocales) {
      const locales = Object.keys(multilocales);
      if (locales) {
        for (const locale of locales) {
          const key = locale
            .replace('organization_description_display_labels_', '')
            .split('_')
            .map((item, i) => (i == 1 ? item.toUpperCase() : item))
            .join('_');
          descriptions[key] = multilocales[locale];
        }
      }
    }
    return descriptions;
  }
  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels.value && this.locales) {
      this.supportedLocales.setValue([]);
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.displayLabels.value);
      for (const key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([localeFound, ...this.supportedLocales.value]);
        }
      }
    }
  }

  getDisplayLabel() {
    if (this.organization && this.organization.displayLabel) {
      return this.organization.displayLabel;
    }
    return '-';
  }

  getAdminGroupDisplayLabel() {
    if (this.adminGroups && this.adminGroups.length > 0 && this.organization && this.orgParentId) {
      return this.adminGroups.find(adminGroup => this.orgParentId === adminGroup.id)?.displayLabel;
    }
    return '-';
  }

  getLocaleDisplayLabel() {
    if (this.locales && this.locales.length > 0 && this.organization && this.organization.defaultLocale) {
      return this.locales.find(locale => locale.localeName === this.organization.defaultLocale).displayLabel;
    }
    return '-';
  }

  getIndustryDisplayLabel() {
    if (this.industries && this.industries.length > 0 && this.organization && this.organization.industry) {
      const industryDisplayLabel = this.industries.find(
        industry => industry.industryName.toUpperCase() === this.organization.industry,
      )?.displayLabel;
      if (industryDisplayLabel != undefined) {
        return industryDisplayLabel || '-';
      }
    }
    return '-';
  }

  getSubindustryDisplayLabel() {
    if (this.subIndustries && this.subIndustries.length > 0 && this.organization && this.organization.subindustry) {
      const subIndustryDisplayLabel = this.subIndustries.find(
        subindustry => subindustry.subIndustryTypeName.toUpperCase() === this.organization.subindustry.toUpperCase(),
      )?.displayLabel;
      if (subIndustryDisplayLabel != undefined) {
        return subIndustryDisplayLabel || '-';
      }
    }
    return '-';
  }

  getDescription() {
    if (this.organization && this.organization.description) {
      return this.organization.description;
    }
    return '-';
  }

  getCRMID() {
    if (this.organization?.alternateIds && this.organization?.alternateIds?.ecrmId) {
      return this.organization?.alternateIds?.ecrmId;
    }
    return '-';
  }

  getTimezoneDisplayLabel() {
    if (this.timezones && this.timezones.length > 0 && this.organization && this.organization?.timezone) {
      return this.timezones.find(
        timezone => timezone.timezoneName.toUpperCase() === this.organization.timezone.toUpperCase(),
      )?.displayLabel;
    }
    return '-';
  }

  private fillSubIndustries() {
    if (this.organization && this.subIndustries) {
      if (this.subIndustries.find(subInd => subInd.subIndustryTypeName === this.organization.subindustry)) {
        return;
      }
    }
    if (this.organization && this.organization.industry && this.organization.industry != this.tempIndustry) {
      this.handleIndustriesSelection(this.organization.industry, false);
      this.tempIndustry = this.organization.industry;
    }
  }

  private getParentId() {
    if (this.organization && this.organization?.relationships.length > 0) {
      const parent = this.organization.relationships.find(
        element => element?.direction === 'INCOMING' && element?.relationshipName === 'TO_CHILD',
      );
      if (parent) {
        this.orgParentId = parent?.relatedSpace?.id;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private validIndustryValidator(industries: any[], key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return industries.some(ind => ind[key] === control.value) ? null : { required: true };
    };
  }
}
