import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { SiteUseType } from '../model/site-use-type';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SiteUseTypesService {
  siteUseTypes$: BehaviorSubject<SiteUseType[]>;

  constructor(private apiService: ApiService) {
    this.siteUseTypes$ = new BehaviorSubject<SiteUseType[]>([]);
  }

  async setSiteUseTypes() {
    const setSiteUse = await this.apiService.get('site_use_types');
    this.siteUseTypes$.next(convertKeys.toCamel(setSiteUse));
  }
}
