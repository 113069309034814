import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalesService } from 'src/app/shared/services/locales.service';
import { TimezonesService } from 'src/app/shared/services/timezones.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { Locale, LocaleBaseConfig } from 'src/app/shared/model/locale.model';
import { Timezone } from 'src/app/shared/model/timezone.model';
import { statuses as CustomerStatus } from 'src/app/shared/model/customer';
import { Subscription } from 'rxjs';
import { SitesService } from 'src/app/shared/services/sites.service';
import { BasicList } from '../site_list/basic-list';
import { SiteGroupService } from 'src/app/shared/services/site-group.service';
import { IconDefinition } from 'ngx-tree-selector';
import { ORG_ICON_DEFINITION } from 'src/app/shared/model/nodeType.model';
import { OrganizationsService } from 'src/app/shared/services/organizations.service';

@Component({
  selector: 'app-site-group',
  templateUrl: './site-group-form.component.html',
  styleUrls: ['./site-group-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SiteGroupFormComponent implements OnInit, OnDestroy {
  readonly CREATE = 'create';
  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  locales: Locale[];
  timezones: Timezone[];
  statuses = CustomerStatus;
  potentialParents: any[];
  org: any;
  descriptionLabel = '';
  descriptionPlaceholder = '';
  multiDescriptionDisplayLabels: any;
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [],
    locales: [],
    defaultLocale: null,
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  namePlaceholder = '';
  userLocale = 'en_US';
  loadingSites = false;
  loadingParents = false;
  multidisplayLabels: any;
  nameLabel = '';
  currentSiteGroup: any;

  @ViewChild('multiLocaleLabels') multiLocaleDisplayLabels: any;
  @ViewChild('multiDisplaylabels') multiDisplaylabels: any;
  @ViewChild('multiLocaleDescriptionLabels') multiLocaleDescriptionDisplayLabels: any;
  @ViewChild('multiDescriptionlabels') multiDescriptionlabels: any;
  sitesSourceList: any[] = [];
  sitesDestinationList: BasicList;
  iconDefinitions: IconDefinition = {
    ...ORG_ICON_DEFINITION,
  };

  private subscriptions: Subscription[] = [];
  private defaultMode: string;

  @Input() siteGroupForm: FormGroup;
  @Input()
  set mode(mode: string) {
    this.defaultMode = mode;
  }
  get mode() {
    return this.defaultMode;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }
  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get displayLabels() {
    return this.siteGroupForm && this.siteGroupForm.get('displayLabels');
  }

  get supportedLocales() {
    return this.siteGroupForm && this.siteGroupForm.get('supportedLocales');
  }

  get status() {
    return this.siteGroupForm && this.siteGroupForm.get('status');
  }

  get defaultLocale() {
    return this.siteGroupForm && this.siteGroupForm.get('defaultLocale');
  }

  get descriptions() {
    return this.siteGroupForm && this.siteGroupForm.get('descriptions');
  }

  get timezone() {
    return this.siteGroupForm && this.siteGroupForm.get('timezone');
  }

  get parentId() {
    return this.siteGroupForm && this.siteGroupForm.get('parentId');
  }

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
    private siteService: SitesService,
    private siteGroupService: SiteGroupService,
    private organizationsService: OrganizationsService,
  ) {
    this.timezones = timezonesService.timezones;
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.nameLabel = this.translateService.instant('group.placeholder.inputLabel');
    this.namePlaceholder = this.translateService.instant('group.placeholder.name');
    this.descriptionLabel = this.translateService.instant('customer.organization.description');
    this.descriptionPlaceholder = this.translateService.instant('customer.organization.create.placeholder.description');
    this.sitesDestinationList = new BasicList('destination');
  }

  ngOnInit(): void {
    this.loadDynamicLists();
    const localeServiceSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      if (this.isCreateMode) {
        this.multiLocaleConfig.defaultLocale = locale;
        this.defaultLocale.patchValue(locale.localeName);
        this.supportedLocales.patchValue([locale]);
      } else if (this.isEditMode) {
        this.multiLocaleConfig.defaultLocale = locale;
        this.buildSupportedLocales();
      }
    });

    const siteServiceSub = this.siteService.potentialParentsList$.subscribe(parents => {
      this.potentialParents = parents;
    });
    const timezoneServiceSub = this.timezonesService.timezones$.subscribe(timezones => {
      this.timezones = timezones;
    });
    const childrenListSub = this.siteService.childrenList$.subscribe(list => {
      this.sitesSourceList = list || [];
    });
    const siteLengthSub = this.siteGroupService.siteLength$.subscribe(length => {
      if (length > 0) {
        this.parentId.disable();
      } else {
        this.parentId.enable();
      }
    });
    const orgHierarchySub = this.siteService.orgHierarchy$.subscribe(orgHierarchy => {
      if (orgHierarchy.length > 0) {
        this.fillChildrenList();
      }
    });

    const siteGroupSub = this.siteGroupService.siteGroupDetails$.subscribe(async siteGroupDetails => {
      if (siteGroupDetails && !this.isCreateMode) {
        this.currentSiteGroup = siteGroupDetails;
        this.fillChildrenList();
        this.siteGroupForm.patchValue(siteGroupDetails);

        const rootOrg = await this.siteService.getRootOrg(siteGroupDetails);
        this.organizationsService.addSelectedOrg(rootOrg.id);

        this.multidisplayLabels = this.currentSiteGroup.displayLabels;
        this.multiDescriptionDisplayLabels = this.currentSiteGroup.descriptions || {};
        this.buildSupportedLocales();
      }
    });

    if (this.isEditMode) {
      this.parentId.disable();
    }

    this.subscriptions = [
      ...this.subscriptions,
      localeServiceSub,
      timezoneServiceSub,
      siteServiceSub,
      childrenListSub,
      siteLengthSub,
      siteGroupSub,
      orgHierarchySub,
    ];
  }

  async fillChildrenList() {
    if (this.currentSiteGroup) {
      const siteGroupParent = {
        id: this.currentSiteGroup.parentId,
        spaceType: this.currentSiteGroup.topology[0].spaceType,
      };
      const children: any[] = (await this.siteService.getSitesFromParent(siteGroupParent)) || [];
      const siteGroup = children?.find(child => child.id === this.currentSiteGroup.id);
      const siteGroupChildren = siteGroup?.children || [];
      this.sitesDestinationList.list = [...siteGroupChildren];
      this.sitesSourceList = children?.filter(child => child.id !== this.currentSiteGroup.id);
      const destinationList = [...this.sitesDestinationList.list];
      this.siteGroupService.setSitesSelected(destinationList);
      this.siteGroupService.setInitialState(destinationList);
    }
  }

  ngAfterViewInit() {
    const multiLocalesSub = this.multiLocaleDisplayLabels.form.valueChanges.subscribe(() => {
      if (this.multiDisplaylabels) {
        const newValue = this.multiDisplaylabels._returnModel;
        setTimeout(() => {
          this.displayLabels.setValue({ ...newValue });
          this.displayLabels.markAsTouched();
          const isValid = this.validateMultiLocale(newValue, this.multiDisplaylabels);
          if (!isValid) {
            this.displayLabels.setErrors({ required: true });
          } else {
            this.displayLabels.setErrors(null);
          }
        }, 10);
      }
    });
    const multiLocalesDescriptionSub = this.multiLocaleDescriptionDisplayLabels.form.valueChanges.subscribe(() => {
      const newValue = this.multiDescriptionlabels._returnModel;
      const status = this.multiLocaleDescriptionDisplayLabels.form.status;
      setTimeout(() => {
        this.updateDescriptionDisplayLabels(status, newValue);
      }, 0);
    });
    this.subscriptions = [...this.subscriptions, multiLocalesSub, multiLocalesDescriptionSub];
  }

  ngOnDestroy(): void {
    this.siteService.setChildrenList([]);
    this.siteGroupService.setSitesSelected([]);
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.parentId.reset();
  }

  async loadDynamicLists() {
    this.loadingParents = !this.isEditMode;
    this.siteService.getPotentialParents();
    this.loadingParents = false;
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  handleSupportedLocalesSelection() {
    this.triggerValidation();
    const selectedLocales = this.supportedLocales.value.map(locale => locale.localeName);
    if (!selectedLocales.includes(this.defaultLocale.value)) {
      this.defaultLocale.setValue(selectedLocales[0]);
    }
  }

  triggerValidation() {
    this.displayLabels.markAsTouched();
    this.displayLabels.updateValueAndValidity();
  }

  onlyLocaleSelected(locale) {
    return this.supportedLocales.value.length == 1 && this.supportedLocales.value[0] == locale;
  }

  async handleSelectionChangeParent(parent) {
    this.loadingSites = true;
    this.sitesSourceList = [];
    const { id, spaceType } = parent;
    this.siteService.getSitesFromParent({ id, spaceType }).then(() => (this.loadingSites = false));
  }

  isMovedItem(data: boolean) {
    if (this.isEditMode) {
      if (this.sitesDestinationList.list.length > 0) {
        this.parentId.disable();
      }
    }
  }

  validateMultiLocale(newValue, multiDisplayLabels) {
    if (Object.keys(newValue).length === 0) {
      return false;
    }
    const transforNewValueToArray = Object.getOwnPropertyNames(newValue);
    const locales = multiDisplayLabels.supportedLocales.map(locale => locale.localeName);
    let result;
    for (const locale of locales) {
      result = transforNewValueToArray.includes(locale);
    }
    if (!result) {
      return false;
    }
    for (const locale in newValue) {
      if (newValue[locale] === '' || newValue[locale] === undefined) {
        return false;
      }
    }
    return true;
  }

  updateDescriptionDisplayLabels(status, newValue) {
    this.descriptions.setValue({ ...newValue });
    this.descriptions.markAsTouched();
    if (status === 'INVALID') {
      this.descriptions.setErrors({ required: true });
    } else {
      this.descriptions.setErrors(null);
    }
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels.value && this.locales) {
      this.supportedLocales.setValue([]);
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.displayLabels.value);
      for (const key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([localeFound, ...this.supportedLocales.value]);
        }
      }
    }
  }
}
