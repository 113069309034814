import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocalesService } from '../../shared/services/locales.service';
import { TimezonesService } from '../../shared/services/timezones.service';
import { Timezone } from '../../shared/model/timezone.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { TranslateService } from '@ngx-translate/core';
import { SiteUseTypesService } from '../../shared/services/site-use-types.service';
import { IndustriesService } from '../../shared/services/industries.service';
import { CountriesService } from '../../shared/services/countries.service';
import { map, Subscription } from 'rxjs';
import { OrganizationsService } from '../../shared/services/organizations.service';
import { SitesService } from 'src/app/shared/services/sites.service';
import { ActivatedRoute } from '@angular/router';
import { FormValidatorService } from '../../shared/services/form-validator.service';
import {EcrmService} from "../../shared/services/ecrm.service";
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-site',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SiteFormComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';

  APPPREFIX = 'prt';

  multidisplayLabels: any;
  multiDescriptionsLabels: any;
  locales: Locale[];
  timezones: Timezone[];
  allOrgs: any[];
  potentialParents: any[] = [];
  filteredParents: any[] = [];
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  types = [
    {
      id: 1,
      name: 'ORGANIZATION',
      displayLabel: 'Organization',
    },
    {
      id: 2,
      name: 'SITE',
      displayLabel: 'Site',
    },
    {
      id: 3,
      name: 'SITE_GROUP',
      displayLabel: 'Site Group',
    },
  ];
  namePlaceholder = '';
  nameLabel = '';
  descriptionLabel = '';
  descriptionPlaceholder = '';
  userLocale = 'en_US';
  displayLabelPlaceholder = '';
  displayLabelName = '';
  private subscriptions: Subscription[] = [];

  @ViewChild('multiLocaleLabels') multiLocaleDisplayLabels: any;
  @ViewChild('multiDisplaylabels') multiDisplaylabels: any;
  @ViewChild('multiLocaleDescriptionLabels') multiLocaleDescriptionDisplayLabels: any;
  @ViewChild('multiDescriptionlabels') multiDescriptionlabels: any;
  @Input() siteForm: FormGroup;
  selectedIndustry = false;
  siteId: any;

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  selectedId = 0;
  @ViewChild("auto", { static: true }) autoc: MatAutocomplete;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  // GETTERS FORMS STARTS

  get displayLabels() {
    return this.siteForm.get('displayLabels');
  }

  get siteUseTypes() {
    return this.siteForm.get('siteUseTypes');
  }

  get supportedLocales() {
    return this.siteForm.get('supportedLocales');
  }

  get defaultLocale() {
    return this.siteForm.get('defaultLocale');
  }

  get timezone() {
    return this.siteForm.get('timezone');
  }

  get parentId() {
    return this.siteForm.get('parentId');
  }

  get floorSpace() {
    return this.siteForm.get('floorSpace');
  }

  get descriptions() {
    return this.siteForm.get('descriptions');
  }

  get country() {
    return this.siteForm.get('country');
  }

  get address1() {
    return this.siteForm.get('address1');
  }

  get address2() {
    return this.siteForm.get('address2');
  }

  get city() {
    return this.siteForm.get('city');
  }

  get state() {
    return this.siteForm.get('state');
  }

  get postalCode() {
    return this.siteForm.get('postalCode');
  }

  get industry() {
    return this.siteForm.get('industry');
  }

  get subIndustry() {
    return this.siteForm.get('subIndustry');
  }

  get ecrmId() {
    return this.siteForm.get('ecrmId');
  }
  // GETTERS FORMS ENDS

  // GETTERS TO POPULATE DROPDOWNS STARTS
  get siteUseTypesData() {
    return this.siteUseTypesService.siteUseTypes$;
  }

  get industries() {
    return this.industriesService.industries$;
  }

  get subIndustries() {
    return this.industriesService.subIndustries$;
  }

  get provinces() {
    return this.countriesService.provinces$;
  }

  get countries() {
    return this.countriesService.getCountries();
  }

  get loadingSubIndustries() {
    return this.industriesService.getLoadingSubIndustries();
  }

  get loadingParents() {
    return this.siteService.getLoadingParents();
  }

  get organizations() {
    return this.orgsService.orgs$;
  }

  get loadingOrgs() {
    return this.orgsService.getLoadingOrgs();
  }
  // GETTERS TO POPULATE DROPDOWNS ENDS

  private _mode: string;
  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private siteUseTypesService: SiteUseTypesService,
    private industriesService: IndustriesService,
    private countriesService: CountriesService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
    private orgsService: OrganizationsService,
    private siteService: SitesService,
    private formValidatorService: FormValidatorService,
    private organizationsService: OrganizationsService,
    private ecrmService: EcrmService
  ) {
    this.displayLabelPlaceholder = this.translateService.instant('customer.create.placeholder.site_name');
    this.displayLabelName = this.translateService.instant('customer.site_name');
    this.descriptionPlaceholder = this.translateService.instant('customer.create.placeholder.description');
    this.descriptionLabel = this.translateService.instant('customer.description_label');
    this.timezones = timezonesService.timezones;
    this.nameLabel = this.translateService.instant('template.template_name');
    this.namePlaceholder = this.translateService.instant('template.placeholder.template_name');
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    const routeSub = this.route.params.subscribe(async params => {
      if (params) {
        this.siteId = params.id;
      }
    });
    this.subscriptions = [routeSub];
  }

  ngOnInit() {
    this.loadDynamicLists();
    const localesSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
      this.supportedLocales.patchValue([locale]);

      if (this.isCreateMode) {
        this.defaultLocale.patchValue(locale.localeName);
      }

      if (this.isEditMode) {
        const siteSub = this.siteService.site$.subscribe(async site => {
          if (site) {
            this.formValidatorService.triggerFormValidation(this.siteForm);
            this.multidisplayLabels = this.displayLabels.value;
            this.multiDescriptionsLabels = this.descriptions.value;
            const countriesSub = this.countries.subscribe(countries => {
              if (countries.length > 0 && countries.find(country => country.name == this.country.value)) {
                if (this.siteForm) {
                  this.siteForm.get('country').setValidators([Validators.required, this.validSelectionValidator(countries, 'name')]);
                }
                this.buildProvinces(this.country.value, false);
              }
            });
            const industryChecker = this.industries.value.some((ind) => ind['industryName'] === this.industry.value);
            if (!industryChecker) {
              this.siteForm.get('industry').setValidators([Validators.required, this.validSelectionValidator(this.industries.value, 'industryName')]);
            } else {
              this.handleIndustriesSelection(this.industry.value, false);
            }
            this.buildSupportedLocales();
            this.subscriptions.push(countriesSub);
            const rootOrg = await this.siteService.getRootOrg(site);
            this.orgsService.addSelectedOrg(rootOrg.id);
            this.parentId.setValue(site.parent);
          }
        });
        this.subscriptions.push(siteSub);
      }
    });

    const organizationsSub = this.orgsService.orgs$.subscribe(async orgs => {
      if (orgs && orgs.length > 0) {
        this.allOrgs = orgs;
      }
    });

    const potentialParentsSub = this.siteService.potentialParentsList$.subscribe(parents => {
      this.potentialParents = parents || [];
      this.filteredParents = this.potentialParents;
      if(parents) {
        this.siteForm.get('parentId').reset();
        this.formValidatorService.triggerControlValidation(this.siteForm.get('parentId'));
      }
    });

    const siteSub = this.orgsService.selectedOrgs$.subscribe(selectedOrg => {
      if (selectedOrg && selectedOrg.length > 0 && this.isCreateMode) {
        const loadingSub = this.loadingOrgs.subscribe(loadingOrgs => {
          if (this.allOrgs && !loadingOrgs) {
            const firstSelectedOrg = this.allOrgs.find(org => selectedOrg.includes(org.id));
            this.parentId.setValue(firstSelectedOrg.id);
          }
        })
      }
    });

    this.siteForm.get('parentId').valueChanges
      .pipe(
        map(value => {
        if(typeof value === "string") {
          if(value == "") {
            this.filteredParents = this.potentialParents;
          }
          else {
            this.filteredParents = this.potentialParents.filter(option =>
              option.displayLabel.toLowerCase().includes(value.toLowerCase())
            );
          }
        }
        else {
          this.filteredParents = this.potentialParents;
        }
        })
      )
      .subscribe();

    if (this.isEditMode) {
      this.parentId.disable();
    }
    this.subscriptions = [...this.subscriptions, localesSub, organizationsSub, siteSub, potentialParentsSub];
  }
  displayFn(parent: any): string {
    return parent && parent.displayLabel ? parent.displayLabel : "";
  }

  registerSelection(value: any) {
    this.selectedId = this.potentialParents.findIndex(val => val.id == value.id);
  }
  ngAfterViewInit() {
    const openSelectSub = this.autoc.opened.subscribe((opened: any) => {
        this.viewport.scrollToIndex(this.selectedId);
    });
    const multilocalesSub = this.multiLocaleDisplayLabels.form.valueChanges.subscribe(() => {
      const newValue = this.multiDisplaylabels._returnModel;
      setTimeout(() => {
        this.displayLabels.setValue({ ...newValue });
        this.displayLabels.markAsTouched();
        const isValid = this.validateMultiLocale(newValue, this.multiDisplaylabels);
        if (!isValid) {
          this.displayLabels.setErrors({ required: true });
        } else {
          this.displayLabels.setErrors(null);
        }
      }, 10);
    });
    const multilocalesDescriptionSub = this.multiLocaleDescriptionDisplayLabels.form.valueChanges.subscribe(() => {
      const newValue = this.multiDescriptionlabels._returnModel;
      const status = this.multiLocaleDescriptionDisplayLabels.form.status;
      setTimeout(() => {
        this.updateDescriptionDisplayLabels(status, newValue);
      }, 0);
    });

    const ecrmCheckSub = this.siteForm.controls['ecrmId'].valueChanges.subscribe({
      next: async (ecrmIdValue: string) => {
        if (this.siteForm.controls['ecrmId'].valid && ecrmIdValue.length > 0) {
          await this.ecrmService.checkTranslation(ecrmIdValue, this.siteId);
        }
      }
    });

    const flexSub = this.ecrmService.checkFlexTranslation$.subscribe({
      next: async (check: boolean) => {
        if (!check) {
          this.siteForm.get('ecrmId').setErrors({'existing': true});
        }
      }
    });

    this.subscriptions = [...this.subscriptions, multilocalesSub, multilocalesDescriptionSub, openSelectSub];
  }

  validateMultiLocale(newValue, multiDisplaylabels) {
    if (Object.keys(newValue).length === 0) {
      return false;
    }
    const transforNewValueToArray = Object.getOwnPropertyNames(newValue);
    const locales = multiDisplaylabels.supportedLocales.map(locale => locale.localeName);
    let result;
    for (const locale of locales) {
      result = transforNewValueToArray.includes(locale);
    }
    if (!result) {
      return false;
    }
    for (const locale in newValue) {
      if (newValue[locale] == '' || newValue[locale] == undefined) {
        return false;
      }
    }
    return true;
  }

  updateDescriptionDisplayLabels(status, newValue) {
    this.descriptions.setValue({ ...newValue });
    this.descriptions.markAsTouched();
    if (status == 'INVALID') {
      this.descriptions.setErrors({ required: true });
    } else {
      this.descriptions.setErrors(null);
    }
  }

  updateDisplayLabels(status, newValue) {
    this.displayLabels.setValue({ ...newValue });
    this.displayLabels.markAsTouched();
    if (status == 'INVALID') {
      this.displayLabels.setErrors({ required: true });
    } else {
      this.displayLabels.setErrors(null);
    }
  }

  onCountryChange($event) {
    this.buildProvinces($event.value, true);
    this.formValidatorService.triggerControlValidation(this.state);
  }

  private buildProvinces(countryName: string, isFromEvent: boolean) {
    this.state.enable();

    if (isFromEvent) {
      this.state.setValue('');
    }
    this.countriesService.setStates(countryName);
  }

  handleSelectionChange() {
    const selectedLocales = this.supportedLocales.value.map(locale => locale.localeName);
    if (!selectedLocales.includes(this.defaultLocale.value)) {
      this.defaultLocale.setValue(selectedLocales[0]);
    }
    this.formValidatorService.triggerControlValidation(this.displayLabels);
    this.formValidatorService.triggerControlValidation(this.defaultLocale);
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels?.value && this.locales) {
      this.supportedLocales.patchValue([]);
      const keys = Object.keys(this.displayLabels.value);
      for (const key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([...this.supportedLocales.value, localeFound]);
        }
      }
    }
  }

  onIndustryChange($event) {
    this.industriesService.loadingSubIndustries$.next(true);
    this.subIndustry.disable();
    this.handleIndustriesSelection($event.value, true);
    this.formValidatorService.triggerControlValidation(this.subIndustry);
  }

  handleIndustriesSelection(industryName, fromEvent: boolean) {
    const loadingSubIndustriesSub = this.industriesService.loadingSubIndustries$.subscribe(loadingSubIndustries => {
      if (!loadingSubIndustries) {
        this.subIndustry.enable();
      }
    });
    if (fromEvent) {
      this.subIndustry.setValue('');
    }
    this.industriesService.setSubIndustries(industryName);
    this.subscriptions.push(loadingSubIndustriesSub);
  }

  onSearchCrmIdChange(): void {
    this.formValidatorService.triggerControlValidation(this.ecrmId);
  }

  onSearchSiteFloorSpaceChange(): void {
    this.formValidatorService.triggerControlValidation(this.floorSpace);
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  onlyLocaleSelected(locale) {
    return this.supportedLocales.value.length == 1 && this.supportedLocales.value[0] == locale;
  }

  private validSelectionValidator(countries: any[], key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return countries.some((ind) => ind[key] === control.value) ? null : { required: true }
    }
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
    this.countriesService.setCountries();
    this.industriesService.setIndustries();
    this.siteUseTypesService.setSiteUseTypes();
    if(this.isCreateMode) {
      this.siteService.getPotentialParents();
    }
    this.orgsService.getOrgs();
    if (this.isEditMode) {
      this.siteService.getSite(this.siteId);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
