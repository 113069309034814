<ng-container *ngIf="isCreateMode || isEditMode">
    <div ngModelGroup="app-org" id="orgForm" [formGroup]="orgForm">
        <!-- NAME -->
        <form #multiLocaleLabels="ngForm" class="mat-form-field column">
            <div class="mat-form-field-container" data-automation="name">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [(ngModel)]="multidisplayLabels"
                    [locales]="supportedLocales.value"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [placeholderText]="namePlaceholder"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [required]="true"
                    [id]="'organization_display_labels'"
                    #multiDisplaylabels
                >
                </multi-locale-input>
            </div>
        </form>

        <!-- LEGAL NAME -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="legalNameLabel">{{ 'customer.organization.legal_name' | translate }}</mat-label>
            <input
                matInput
                id="legalName"
                type="text"
                name="legalName"
                placeholder="{{ 'customer.create.placeholder.organization.legal_name' | translate }}"
                formControlName="legalName"
                data-automation="legalName"
                data-url-persist
                required
            />
            <mat-error *ngIf="legalName.hasError('required')">
                <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- Parent -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="parentLabel">{{ 'customer.organization.parent' | translate }}</mat-label>
            <mat-select
                required
                formControlName="parent"
                data-automation="parent"
                placeholder="{{ 'customer.create.placeholder.parent' | translate }}"
                id="parent"
                [disabled]="isEditMode"
            >
                <mat-option *ngFor="let grp of adminGroups" [value]="grp.id">
                    {{ grp.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="parent.hasError('required')">
                <span data-automation="parentValidationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- Industry -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="industryLabel">{{ 'customer.organization.industry' | translate }}</mat-label>
            <mat-select
                required
                formControlName="industry"
                data-automation="industry"
                placeholder="{{ 'customer.create.placeholder.industry' | translate }}"
                id="industry"
                (selectionChange)="onIndustryChange($event)"
            >
                <mat-option *ngFor="let industry of industries" [value]="industry.industryName">
                    {{ industry.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="industry.hasError('required')">
                <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- SubIndustry -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="subIndustryLabel">{{
                'customer.organization.subindustry' | translate
            }}</mat-label>
            <mat-select
                required
                formControlName="subIndustry"
                data-automation="subIndustry"
                placeholder="{{ 'customer.create.placeholder.subindustry' | translate }}"
                id="subindustry"
            >
                <mat-option *ngFor="let subIndustry of subIndustries" [value]="subIndustry.subIndustryTypeName">
                    {{ subIndustry.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="subIndustry.hasError('required')">
                <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
            <mat-hint *ngIf="loadingSubIndustries | async" align="end">{{ 'customer.loading' | translate }}</mat-hint>
            <mat-spinner *ngIf="loadingSubIndustries | async" matSuffix diameter="23"></mat-spinner>
        </mat-form-field>

        <!-- SUPPORTED LOCALES -->
        <mat-form-field class="column" appearance="outline" floatLabel="always" data-automation="supportedLocalesField">
            <mat-label data-automation="supportedLocalesLabel">{{
                'customer.organization.supported_locales' | translate
            }}</mat-label>
            <mat-select
                formControlName="supportedLocales"
                data-automation="supportedLocale(s)"
                id="supported-locales"
                (selectionChange)="handleSupportedLocalesSelection()"
                multiple
            >
                <mat-option
                    *ngFor="let locale of locales"
                    [value]="locale"
                    [disabled]="limitLocaleSelectList(5, locale) || onlyLocaleSelected(locale)"
                >
                    {{ locale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- DEFAULT LOCALE -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="localeLabel"
                >{{ 'customer.organization.default_locale' | translate }}
            </mat-label>
            <mat-select required formControlName="defaultLocale" data-automation="defaultLocale" id="locale">
                <mat-option
                    *ngFor="let supportedLocale of orgForm.controls.supportedLocales.value"
                    [value]="supportedLocale.localeName"
                >
                    {{ supportedLocale.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error class="alert" *ngIf="defaultLocale.hasError('required')">
                <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- Time Zone -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="timezoneLabel">{{ 'customer.timezone' | translate }}</mat-label>
            <mat-select
                required
                formControlName="timezone"
                data-automation="timezone"
                placeholder="{{ 'customer.create.placeholder.timezone_selector' | translate }}"
                id="timezone"
            >
                <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                    {{ timezone.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- STATUS -->
        <mat-form-field class="column" appearance="outline">
            <mat-label data-automation="statusLabel">{{ 'customer.organization.status' | translate }}</mat-label>
            <mat-select
                required
                formControlName="status"
                data-automation="status"
                placeholder="{{ 'customer.create.placeholder.status' | translate }}"
                id="status"
            >
                <mat-option *ngFor="let status of statuses" [value]="status.id">
                    {{ status.display_label | translate }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="status.hasError('required')">
                <span data-automation="validationMessage">{{ 'customer.validation.required' | translate }}</span>
            </mat-error>
        </mat-form-field>

        <!-- ECRM ID -->
        <mat-form-field class="column" appearance="outline" floatLabel="always" data-automation="ecrmIdField">
            <mat-label data-automation="ecrmIdLabel">{{ 'customer.organization.ecrm_id' | translate }}</mat-label>
            <input
                matInput
                id="ecrmId"
                type="text"
                name="ecrmId"
                placeholder="{{ 'customer.create.placeholder.crm_id' | translate }}"
                formControlName="ecrmId"
                data-automation="crmId"
                data-url-persist
            />
            <mat-error *ngIf="ecrmId.hasError('pattern')">
                {{ 'customer.validation.invalid.crm_id.pattern' | translate }}
            </mat-error>
            <mat-error *ngIf="ecrmId.hasError('existing')">
                {{ 'customer.validation.invalid.crm_id.existing' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- DESCRIPTION -->
        <form #multiLocaleDescriptionLabels="ngForm" class="mat-form-field column">
            <div class="mat-form-field-container" data-automation="description">
                <multi-locale-input
                    name="descriptions"
                    [inputLabel]="descriptionLabel"
                    [(ngModel)]="multiDescriptionDisplayLabels"
                    [locales]="supportedLocales.value"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [required]="false"
                    [id]="'organization_description_display_labels'"
                    #multiDescriptionlabels
                >
                </multi-locale-input>
            </div>
        </form>
    </div>
</ng-container>
<ng-container *ngIf="isViewMode">
    <div class="view-organization">
        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.legal_name' | translate }}:</mat-label>
            <div class="field-value" data-automation="legalName">
                {{ organization?.legalName }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.parent_admin_group' | translate }}:</mat-label>
            <div class="field-value" data-automation="parentAdminGroup">
                {{ getAdminGroupDisplayLabel() }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.industry' | translate }}:</mat-label>
            <div class="field-value" data-automation="industry">
                {{ getIndustryDisplayLabel() }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.subindustry' | translate }}:</mat-label>
            <div class="field-value" data-automation="subIndustry">
                {{ getSubindustryDisplayLabel() }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.timezone' | translate }}:</mat-label>
            <div class="field-value" data-automation="timezone">
                {{ getTimezoneDisplayLabel() }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.locale' | translate }}:</mat-label>
            <div class="field-value" data-automation="locale">
                {{ getLocaleDisplayLabel() }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.status' | translate }}:</mat-label>
            <div class="field-value" data-automation="status">
                {{ organization?.status }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'customer.organization.ecrm_id' | translate }}:</mat-label>
            <div class="field-value" data-automation="crmId">
                {{ getCRMID() }}
            </div>
        </div>

        <div class="view-field-container">
            <mat-label class="field-label">{{ 'organization.description' | translate }}:</mat-label>
            <div class="field-value" data-automation="description">
                {{ getDescription() }}
            </div>
        </div>
    </div>
</ng-container>
