<div class="app-site-view content" *ngIf="siteData">
    <form #view="ngForm" class="site-form">
        <div class="form-group">
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site.parent_id' | translate }}:</mat-label>
                <div class="field-value" data-automation="parent">{{ getParentDisplayLabel() }}</div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site.locale' | translate }}:</mat-label>
                <div class="field-value" data-automation="locale">
                    {{ getLocaleDisplayLabel() }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site.industry' | translate }}:</mat-label>
                <div class="field-value" data-automation="industry">
                    {{ getIndustryDisplayLabel() }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site.timezone' | translate }}:</mat-label>
                <div class="field-value" data-automation="timezone">{{ getTimezoneDisplayLabel() }}</div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site.subindustry' | translate }}:</mat-label>
                <div class="field-value" data-automation="subIndustry">{{ getSubindustryDisplayLabel() }}</div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site.use_type' | translate }}:</mat-label>
                <div class="field-value" data-automation="siteUseType">{{ getUseType() }}</div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'site.floor_space' | translate }}:</mat-label>
                <div class="field-value" data-automation="siteFloorSpace">{{ getFloorSpace() }}</div>
            </div>
            <div class="view-field-container small nowrap">
                <mat-label class="field-label">{{ 'customer.description' | translate }}:</mat-label>
                <div class="field-value" data-automation="description">
                    {{ getDescription() }}
                </div>
            </div>
            <div class="view-field-container small nowrap">
                <mat-label class="field-label">{{ 'site.crm_id' | translate }}:</mat-label>
                <div class="field-value nowrap" data-automation="crmId">{{ getECRMId() }}</div>
            </div>
            <div class="view-field-container small nowrap">
                <mat-label class="field-label">{{ 'site.address' | translate }}:</mat-label>
                <div class="field-value" data-automation="address">
                    <address>
                        {{ getPrimaryAddress() }}<br />
                        <div *ngIf="getSecondaryAddress() !== '-'" data-automation="secondaryAddress">
                            {{ getSecondaryAddress() }}<br />
                        </div>
                        {{ getCity() }}, {{ getState() }}, {{ getPostalCode() }}<br />
                        {{ getCountryDisplayLabel() }}
                    </address>
                </div>
            </div>
        </div>
    </form>
</div>
