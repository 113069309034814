import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale } from '../shared/model/locale.model';
import { Customer } from '../shared/model/customer';
import { CookieService } from '../shared/services/cookie.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { SitesService } from '../shared/services/sites.service';
import { SiteGroupService } from '../shared/services/site-group.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  form: FormGroup;
  customerId = '';
  organizationId: string;
  organizationData: any;
  siteId: string;
  siteData: any;
  siteGroupId: string;
  siteGroupData: any;
  subscriptions = [];
  locales: Locale[];
  timezones: Timezone[];
  labels: Customer[];
  selectedOrgs: any[];
  customerssList: Customer[] = [];
  loadingHeaderData = false;
  type: string;
  entityId: string;
  loadingHierarchy: boolean;

  customer: Customer = {
    id: '',
    displayLabel: '',
    locales: '',
    timezone: '',
  };

  BAD_REQUEST = 'Oops, There was a problem with your request';
  CONFLICT = 'There is a data conflict with this customer';
  readonly mode = 'view';

  constructor(
    private siteGroupService: SiteGroupService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private cookieService: CookieService,
    private router: Router,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private organizationsService: OrganizationsService,
    private sitesService: SitesService,
  ) {
    const badRequestSub = this.translateService.get('customer.notification.bad_request').subscribe((result: string) => {
      this.BAD_REQUEST = this.translateService.instant('customer.notification.bad_request');
    });
    this.timezones = timezonesService.timezones;
    const localesSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
    });
    this.loadDynamicLists();

    const orgDetailsSub = this.organizationsService.orgDetails$.subscribe(orgDetails => {
      if (orgDetails) {
        this.organizationData = orgDetails;
      }
    });
    const loadingHierarchySub = this.sitesService.loadingHierarchy$.subscribe(loading => {
      this.loadingHierarchy = loading;
    });
    const errorLoadOrgSub = this.organizationsService.errorLoadingOrg$.subscribe(errorDetails => {
      if (errorDetails && errorDetails?.error?.detail === 'ERR_BAD_REQUEST') {
        this.messageService.setError(this.BAD_REQUEST);
      }
    });
    const errorLoadSiteSub = this.sitesService.errorLoadingSite$.subscribe(errorDetails => {
      if (errorDetails && errorDetails?.error?.detail === 'ERR_BAD_REQUEST') {
        this.messageService.setError(this.BAD_REQUEST);
      }
    });
    const orgLoaderSub = this.organizationsService.orgDetails$.subscribe(() => {
      this.loadingHeaderData = false;
    });
    const selectedOrgsSub = this.organizationsService.selectedOrgs$.subscribe(value => {
      this.selectedOrgs = value;
      const loadingOrgs = this.organizationsService.orgLoader$.getValue();
      this.type = this.route.queryParams['value']['type']?.toUpperCase();
      if (this.selectedOrgs.length === 0 && !this.router.isActive('/', true) && !loadingOrgs && this.type !== 'SITE') {
        return this.router.navigate(['/'], { queryParams: { type: 'no-selected-org' } });
      }
      if (this.organizationId && !this.selectedOrgs.includes(this.organizationId) && this.type !== 'SITE') {
        const firstOrgId = this.selectedOrgs[0];
        return this.router.navigate([`details/${firstOrgId}/view`], { queryParams: { type: 'organization' } });
      }
    });

    const siteDataSub = this.sitesService.site$.subscribe(value => {
      this.siteData = value;
      this.loadingHeaderData = false;
    });
    const siteGroupSub = this.siteGroupService.siteGroupDetails$.subscribe(siteDetails => {
      if (siteDetails) {
        this.siteGroupData = siteDetails;
        this.loadingHeaderData = false;
      }
    });

    this.subscriptions = [
      ...this.subscriptions,
      orgDetailsSub,
      errorLoadOrgSub,
      errorLoadSiteSub,
      orgLoaderSub,
      badRequestSub,
      localesSub,
      selectedOrgsSub,
      siteDataSub,
      siteGroupSub,
      loadingHierarchySub,
    ];
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async params => {
      if (this.route.queryParams['value']['type']) {
        this.type = this.route.queryParams['value']['type'].toUpperCase();
      }
      this.entityId = params.id;
      this.loadingHeaderData = true;
    });

    this.subscriptions.push(routeSub);
  }

  get locale() {
    return this.cookieService.getLocale();
  }

  getLocaleDisplayLabel(): string {
    if (this.locales && this.locales.length > 0 && this.customer && this.customer.locales) {
      return this.locales.find(locale => locale.localeName === this.customer.locales).displayLabel;
    }
    return '-';
  }

  getTimezoneDisplayLabel(): string {
    if (this.timezones && this.timezones.length > 0 && this.customer && this.customer.timezone) {
      return this.timezones.find(timezone => timezone.timezoneName === this.customer.timezone).displayLabel;
    }
    return '-';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.router.navigate(['list']);
  }

  openDeleteDialog(): void {
    const dialogConfig = new MatDialogConfig();
    let data = {} as any;
    switch (this.type) {
      case 'SITE':
        data = this.siteData;
        break;
      case 'SITE_GROUP':
        data = this.siteGroupData;
        break;
      case 'ORGANIZATION':
        data = this.organizationData;
        break;
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      id: data.id,
      displayLabel: data.displayLabel,
      spaceType: data.spaceType,
      type: this.type,
      node: data
    };
    dialogConfig.width = '500px';
    this.dialog.open(DeleteDialogComponent, dialogConfig);
  }

  disableDelete() {
    let data;
    switch (this.type) {
      case 'SITE_GROUP':
        return false;
      case 'SITE':
        data = this.siteData;
        break;
      case 'ORGANIZATION':
        data = this.organizationData;
        break;
    }
    if (data) {
      return data.relationships.some(relation => relation.direction == 'OUTGOING');
    }
    return true;
  }

  handleEdit() {
    this.router.navigate([`details/${this.entityId}/edit`], {
      queryParams: {
        type: this.type?.toLowerCase(),
      },
    });
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }
}
