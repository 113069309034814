import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConedService } from '../shared/services/coned.service';
import { ServiceAccountsService } from '../shared/services/service-accounts.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit {
  authCode: string;
  error: any;

  constructor(
    private route: ActivatedRoute,
    private conedService: ConedService,
    private serviceAccountsService: ServiceAccountsService
  ) {
    this.conedService.customerInfo$.subscribe(async (customerInfo) => {
      if (customerInfo) {
        if (customerInfo.error) {
          this.error = customerInfo.response;
        }
        const resource_uri = customerInfo.response.resourceURI;
        const subscription_id = resource_uri.substring(
          resource_uri.lastIndexOf('/') + 1
        );
        const account_number = atob(customerInfo.response.accountNumber);
        const refresh_token = customerInfo.response.refresh_token;
        const portal_id = subscription_id.concat(' ', refresh_token);
        const serviceAccounts = await this.serviceAccountsService.getServiceAccounts();
        const serviceAccount: any = serviceAccounts.filter(
          (serviceAccount) =>
            serviceAccount.alternateIds?.serviceAccountNumber === account_number
        );
        if (serviceAccount.length > 0){
          this.serviceAccountsService.updateServiceAccounts(
            serviceAccount[0],
            portal_id
          );
        }
        console.dirxml(customerInfo.response);
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.authCode = params.get('code');
      if (this.authCode) {
        this.conedService.authorizeCustomer(this.authCode);
      }
    });
  }
}
