import { OrganizationsService } from 'src/app/shared/services/organizations.service';
import { SiteGroupService } from 'src/app/shared/services/site-group.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SitesService } from 'src/app/shared/services/sites.service';
import { GlobalAlertService } from 'src/app/shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  dialogState = 'DEFAULT';
  SITE_DELETE_SUCCESS = 'Site was deleted successfully.';
  SITE_GROUP_DELETE_SUCCESS = 'Site group was deleted successfully.';
  ORGANIZATION_DELETE_SUCCESS = 'Organization was deleted successfully.';
  DELETE_ERROR = 'Something went wrong. Please try again.';
  SUCCESS = '';

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private sitesService: SitesService,
    private siteGroupService: SiteGroupService,
    private organizationService: OrganizationsService,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    let transalatableSuccessMsg = '';
    let translateableFailureMsg = 'delete.error.message';

    switch (this.data.type) {
      case 'SITE_GROUP':
        this.SUCCESS = this.SITE_GROUP_DELETE_SUCCESS;
        transalatableSuccessMsg = 'site_group.delete.success';
        translateableFailureMsg = 'site_group.delete.failure';
        break;
      case 'SITE':
        this.SUCCESS = this.SITE_DELETE_SUCCESS;
        transalatableSuccessMsg = 'site.delete.success';
        break;
      case 'ORGANIZATION':
        this.SUCCESS = this.ORGANIZATION_DELETE_SUCCESS;
        transalatableSuccessMsg = 'organization.delete.success';
        break;
    }
    this.translateService.get(transalatableSuccessMsg).subscribe((result: string) => {
      this.SUCCESS = result;
      this.DELETE_ERROR = this.translateService.instant(translateableFailureMsg);
    });

    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/delete.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "cancel",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/cancel.svg")
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    this.dialogState = 'DELETING';
    const { type, id, node } = this.data;
    try {
      switch (type) {
        case 'SITE_GROUP':
          const children = await this.sitesService.getSitesFromParent({id: id, spaceType: 'SPACEGROUP'});
          const childrenResponse = await this.siteGroupService.changeParent({
            children: children,
            currentParentId: id,
            newParentId: node.parentId,
          });
          if(childrenResponse && childrenResponse.failures.length < 1) {
            await this.siteGroupService.deleteSiteGroup(id);
          }
          else if(childrenResponse.failures && childrenResponse.failures.length > 0) {
            this.DELETE_ERROR = this.translateService.instant('site_group.delete.reassign.failure');
            throw Error('Error reassigning Site Group children relationships')
          }
          break;
        case 'SITE':
          await this.sitesService.deleteSite(id);
          break;
        case 'ORGANIZATION':
          await this.organizationService.deleteOrg(id);
          this.organizationService.forceReloadNoCached();
          this.organizationService.setRefreshingList(true);
          this.organizationService.removeDeletedOrg(id);
          await this.organizationService.getOrgs();
          break;
      }
      await this.sitesService.refetchOrgs();
      this.dialogRef.close();
      this.messageService.setSuccess(this.SUCCESS);
      void this.router.navigate(['/']);
    } catch (err) {
      this.dialogRef.close();
      this.messageService.setError(this.DELETE_ERROR);
    }
  }
}
