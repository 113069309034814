import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormValidatorService {
  /* constructor() { } */

  triggerFormValidation(frmGroup: FormGroup) {
    if (Object.keys(frmGroup.controls).length > 0) {
      for (const ctrlValue in frmGroup.controls) {
        frmGroup.controls[ctrlValue].markAsDirty();
        frmGroup.controls[ctrlValue].markAsTouched();
        frmGroup.controls[ctrlValue].updateValueAndValidity();
      }
    }
  }

  triggerControlValidation(control) {
    control.markAsTouched();
    control.markAsDirty();
    control.updateValueAndValidity();
  }
}
